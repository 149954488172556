import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forgotpassword } from 'src/core/shared/model/forgot-password';
import { ForgotPasswordService } from 'src/core/service/forgot-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  forgotForm: FormGroup;
 

  constructor(public formBuilder:FormBuilder,public router:Router, public forgotpasswordservice:ForgotPasswordService,private toastr: ToastrService) { }

  ngOnInit() {

    this.forgotForm=this.formBuilder.group({
      email:['',[Validators.required,Validators.email]]

    });
  }

  onsubmit(){
   
   this.forgotpasswordservice.forgotPassword(this.forgotForm.get('email').value).subscribe((res:any) => 
        {
        const k=res;
        if (res.statusCode == 1) {
          this.toastr.success('Reset password Link is send to your EmailID');
          this.router.navigate(['/login_page']);
         // this.router.navigate(['/reset-password']);
        }
        else{
          this.toastr.success('Invalid Email!!');
        }
        });
}
}
