import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
  private dataSource = new BehaviorSubject('');
  fabricSelectedData = this.dataSource.asObservable();
    fabricData(data: any) {
      this.dataSource.next(data);
   }

   private dataSource1 = new BehaviorSubject('');
  addressSelectedData = this.dataSource1.asObservable();
    addresData(data: any) {
      this.dataSource1.next(data);
   }

  
}
