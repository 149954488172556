import { Component, OnInit, Input } from '@angular/core';
import { AnswerComponent } from '../answer/answer.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  Question=[];
  Ans=[];
  @Input() question:any;
  @Input() ansArray:any;
  @Input() main:any
  ansArrayLength: number;
  i: any;
  pass: any[];
  count: any;
  countofans;
  arrayofans=[];
 que:string;
 ans:string;
  constructor() { 
    this.Ans =this.ansArray;
  }
  ngOnInit() {
  }
  next()
  {
    this.i++;
     if(this.i==this.ansArrayLength)
     {
      alert('over');
     }
     else{
      this.Ans=(this.Question[this.i]);
     }
   
  }
  previous(){
    this.i--;
    this.count++;
    if(this.i==-1)
    {
     alert('over');
    }
    else{
     this.Ans=(this.Ans[this.i]);
    }   
  }
}