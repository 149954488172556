import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorage } from '../shared/constants/localStorage';
import { CartAddService } from '../service/cart-add.service';
import { ToastrService } from 'ngx-toastr';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor( public route: Router,public cartAddService:CartAddService,public toastr:ToastrService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem('token')!=null){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ` + localStorage.getItem('token')
        }
      });
    }
   
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    },
      (err: any) => {
        if (err.status == 401) {
          
          localStorage.clear();
          this.cartAddService.changeData('logout');
          this.toastr.success('Needed to login again your session is expired');
          this.route.navigate(['/login_page']);
        }
    }));
  
  }
}
