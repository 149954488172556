import { Component, OnInit } from '@angular/core';
declare function loadReviewZoom(): any; 
@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.css']
})
export class CustomerReviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(function() {
      loadReviewZoom();
   }, 10);
  }

}
