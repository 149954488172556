import { Component, OnInit, Input } from "@angular/core";
import { debugOutputAstAsTypeScript } from "@angular/compiler";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CartAddService } from "src/core/service/cart-add.service";
import { wishList } from "src/core/shared/model/wishList";
import { WishListService } from "src/core/service/wish-list.service";
import { ProductGetService } from "src/core/service/product-get.service";
import { AuthService } from "src/core/service/auth.service";
import { RegisterService } from "src/core/service/register.service";
import { LocalStorage } from "src/core/shared/constants/localStorage";
import { async } from "@angular/core/testing";
import { SharedService } from "src/core/service/shared.service";
import { environment } from 'src/environments/environment';
declare function addtocartfunc(): any;
@Component({
  selector: "app-arechtech-figer",
  templateUrl: "./arechtech-figer.component.html",
  styleUrls: ["./arechtech-figer.component.css"],
})
export class ArechtechFigerComponent implements OnInit {
  @Input() productDatafig: any;
  cartForm: FormGroup;
  ratingForm: FormGroup;
  submitted = false;
  fabricCode: string;
  quantityStatus: string;
  productMasterImageArray = [];
  productSizesArray: any = [];
  productArrayObject: any;
  productArray = [];
  cartPrice: number;
  token: any;
  response: any;
  currentUser: any;
  wishListobj = new wishList();
  wishlistStatus: Boolean;
  productId: string;
  wishlistArray = [];
  Productdata: any;
  ipAddress: any;
  fabricDetails: any;
  // quantity:number=1;
  // selectedSize:string='';
  fabricStatus: any;
  productcartValue: any = [];
  fabricArray: any = [];
  avreageProductReview: any;
  constructor(
    private toastr: ToastrService,
    private cartAddService: CartAddService,
    private authService: AuthService,
    private userService: RegisterService,
    public router: Router,
    private fb: FormBuilder,
    private productGetService: ProductGetService,
    public wishListService: WishListService,
    public sharedService: SharedService
  ) {
    this.cartForm = this.fb.group({
      quantity: [1, Validators.min(1)],
    });
    this.ratingForm = this.fb.group({
      avreageProductReview: "",
    });
  }
  async ngOnInit() {
    const a = this.productDatafig;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.token = localStorage.getItem("token");
    this.productId = localStorage.getItem("productId");

    this.productArray = JSON.parse(localStorage.getItem(LocalStorage.CART));
    // console.log("abc"+this.productDatafig);
    //
    setTimeout(() => {
      //<<<---    using ()=> syntax
      if (this.token) {
        this.getProductWishlist(this.productDatafig.productId);
        this.productMasterImageArray = this.productDatafig.productMasterImage;
        this.productSizesArray = this.productDatafig.productSizes;
        this.avreageProductReview = this.productDatafig.avreageProductReview;
        this.ratingForm
          .get("avreageProductReview")
          .setValue(this.productDatafig.avreageProductReview);
      }
    }, 3000);

    const res = await this.productGetService.getIPAddress().toPromise();
    this.ipAddress = res;
    const cartValue = {
      IpAddress: this.ipAddress.ip,
      cartData: JSON.stringify(this.productArray),
      url: this.router.url
    };
    this.productGetService.addUpdateCartVisitor(cartValue).subscribe((res) => {
      const a = res;
    });
  }
  async addToCart() {
    setTimeout(async () => {
      //<<<---    using ()=> syntax
      this.productArray = JSON.parse(localStorage.getItem(LocalStorage.CART));
      this.submitted = true;
      if (!this.cartForm.valid) {
        return;
      } else {
        if (this.fabricDetails != undefined || this.fabricDetails != null) {
          if (
            this.productDatafig.productSalePrice === "" ||
            this.productDatafig.productSalePrice == null
          ) {
            this.cartPrice = this.productDatafig.productPrice;
          } else {
            this.cartPrice = this.productDatafig.productSalePrice;
          }

          this.productArrayObject = {
            productId: this.productDatafig.productId,
            image: this.productDatafig.productFeatureImage,
            fabric: this.fabricDetails,
            name: this.productDatafig.productName,
            price: this.cartPrice,
            quantity: this.fabricDetails.fabricQuantity,
            available: this.fabricDetails.fabricQuantity,
            Qty: this.cartForm.get("quantity").value,
          };

          if (
            this.fabricDetails.fabricQuantity <= 0 ||
            this.fabricDetails.fabricQuantity === ""
          ) {
            this.toastr.error("Item is out of stock");
          } else {
            //     if (this.productArray && this.productArray.length > 0)
            //     {
            //
            //     const isProduct = this.productArray.find(product => product.productId === this.productArrayObject.productId);
            //   if (isProduct) {
            //
            //     this.Productdata=isProduct;
            //     const objIndex = this.productArray.findIndex(product => product.productId== isProduct.productId);

            //     isProduct.Qty += this.productArrayObject.Qty;
            //     //const stock=this.productArray[objIndex].quantity;
            //     if(isProduct.Qty >isProduct.quantity)
            //     {
            //       this.quantityStatus='Quantity Is Not Available';
            //       return;
            //     }
            //   }
            // }

            //     if (this.cartForm.get('quantity').value > this.productDatafig.quantity && this.productDatafig.quantity > 0) {

            //   this.quantityStatus = 'Only ' + this.productDatafig.quantity + ' items are available in stock';
            //   return;
            // }
            // else {
            // addtocartfunc();

            if (this.productArray && this.productArray.length > 0) {
              const isProduct = this.productArray.find(
                (product) =>
                  product.productId === this.productArrayObject.productId
              );
              if (isProduct) {
                if (
                  this.productArrayObject.fabric.fabricDetailsId !=
                  isProduct.fabric.fabricDetailsId
                ) {
                  this.toastr.error(
                    "you have already selected product with different fabric"
                  );
                  return;
                }
                this.Productdata = isProduct;
                const objIndex = this.productArray.findIndex(
                  (product) => product.productId == isProduct.productId
                );

                const av = isProduct.quantity - isProduct.Qty;
                const pre = isProduct.Qty;
                isProduct.Qty += this.productArrayObject.Qty;
                //const stock=this.productArray[objIndex].quantity;
                if (isProduct.Qty > isProduct.quantity) {
                  this.toastr.error("Quantity is not available");
                  if (av == 0) {
                    this.quantityStatus = "Quantity is not available";
                    return;
                  } else {
                    this.quantityStatus = "Only" + av + "Products is available";
                    isProduct.Qty = pre;
                    return;
                  }
                  return;
                } else {
                  this.productArray.splice(objIndex, 1);
                  this.productArray.push(isProduct);
                }
              } else {
                if (
                  this.productArrayObject.Qty > this.productArrayObject.quantity
                ) {
                  this.toastr.error("Quantity is not available");
                  this.quantityStatus =
                    "Only " +
                    this.productArrayObject.quantity +
                    " Products is available";
                  return;
                } else {
                  this.productArray.push(this.productArrayObject);
                }
              }
            } else {
              if (
                this.productArrayObject.Qty > this.productArrayObject.quantity
              ) {
                this.toastr.error("Quantity is not available");
                this.quantityStatus =
                  "Only " +
                  this.productArrayObject.quantity +
                  " Products is available";
                return;
              } else {
                this.productArray = [];
                this.productArray.push(this.productArrayObject);
              }
            }
            if (this.authService.isLogin()) {
              const cartValue = {
                cartData: JSON.stringify(this.productArray),
              };
              this.userService.addCart(cartValue);
            } else {
              const res = await this.productGetService
                .getIPAddress()
                .toPromise();
              this.ipAddress = res;
              const cartValue = {
                IpAddress: this.ipAddress.ip,
                cartData: JSON.stringify(this.productArray),
              };
              this.productGetService
                .addUpdateCartVisitor(cartValue)
                .subscribe((res) => {
                  const a = res;
                });
            }

            // const objIndex = this.productArray.findIndex(product => product.productId== this.Productdata.productId);
            // if(this.productArray[objIndex].Qty>this.productArray[objIndex].quantity)
            // {
            //   this.toastr.success('sdsf');
            // }
            // else{
            localStorage.setItem(
              LocalStorage.CART,
              JSON.stringify(this.productArray)
            );
            this.cartAddService.changeData("update");
            this.toastr.success("Product added successfully");
            this.quantityStatus = "";
            this.fabricDetails = null;
            this.sharedService.fabricData(this.fabricDetails);
            this.fabricStatus = "";
            window.scrollTo(0, 0);
            this.router.navigate(['/cart']);
            //  }
            //}
          }
        } else {
          this.toastr.error("Please select fabric color");
          this.fabricStatus = "Please select fabric color";
        }
      }
    }, 2000);
  }
  getProductWishlist(productId) {
    this.wishListService.getProductWishlistById(productId).subscribe((res) => {
      this.wishlistArray = res.data;
    });
  }
  AddtoWishlist() {
    if (this.token === "" || this.token == null) {
      this.toastr.error("Must need to login to add item to wishlist");
    } else {
      this.wishListobj.productId = this.productDatafig.productId;
      this.wishListobj.userId = this.currentUser.userId;
      if (this.wishlistArray.length > 0) {
        this.deleteProductWishlist();
      } else {
        this.wishListService
          .AddtoWishlist(this.wishListobj)
          .subscribe(async (data: any) => {
            if (data.statusCode !== 0) {
              this.response = data.data;
              this.getProductWishlist(this.productDatafig.productId);
              this.toastr.success(
                "Success ",
                "product added successfully to wishlist"
              );
            } else {
              this.toastr.error("Invalid", "Opps somthing went wrong");
            }
          });
      }
    }
  }
  deleteProductWishlist() {
    this.wishListService
      .deleteProductWishlist(this.productDatafig.productId)
      .subscribe((data: any) => {
        if (data.statusCode !== 0) {
          this.getProductWishlist(this.productDatafig.productId);
          this.toastr.success("Success ", "product remove from wishlist");
        } else {
          this.toastr.error("Invalid", "Opps somthing went wrong");
        }
      });
  }
  selectedFabric($event) {
    this.fabricDetails = $event;
    this.fabricStatus = "";
  }
}
