import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { BlogGridComponent } from './blog-grid/blog-grid.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogMasonryComponent } from './blog-masonry/blog-masonry.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';
// import { CompareComponent } from './compare/compare.component';
import { ContactComponent } from './contact/contact.component';

import { QuickViewComponent } from './quick-view/quick-view.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { HeaderComponent } from './Layout/header/header.component';
import { MenuComponent } from './Layout/menu/menu.component';
import { FooterComponent } from './Layout/footer/footer.component';
import { IndexComponent } from './index/index.component';
import { LayoutComponent } from './Layout/layout/layout.component';
import { BannerComponent } from './home/component/banner/banner.component';
import { ProductListComponent } from './home/component/product-list/product-list.component';
import { HomeComponent } from './home/home.component';

import { PromotionsComponent } from './home/component/promotions/promotions.component';
import { ProductDailyDealComponent } from './home/component/product-daily-deal/product-daily-deal.component';
import { ServicesComponent } from './home/component/services/services.component';
import { SpecialLookComponent } from './home/component/special-look/special-look.component';
import { SectionBlogComponent } from './home/component/section-blog/section-blog.component';
import { BlockSocialComponent } from './home/component/block-social/block-social.component';
import { ShopComponent } from './shop/shop..component';

// import { ProductComponent } from './product/home.component';
import { ProductFullwidthComponent } from './product/component/product-fullwidth/product-fullwidth.component';
import { ContactDetailComponent } from './contact/component/contact-detail/contact-detail.component';
import { SellproductComponent } from './product/component/sellproduct/sellproduct.component';
import { ProductComponent } from './product/product.component';
import { ImagebarComponent } from './product/component/imagebar/imagebar.component';
import { ArechtechFigerComponent } from './product/component/arechtech-figer/arechtech-figer.component';
import { ProductDescriptionComponent } from './product/component/product-description/product-description.component';
import { ProductProductTagComponent } from './product/component/product-product-tag/product-product-tag.component';
import { CustomerReviewComponent } from './product/component/customer-review/customer-review.component';
import { DescriptionComponent } from './product/component/description/description.component';
import { ShopFullwidthComponent } from './shop/component/shop-fullwidth/shop-fullwidth.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CompareComponent } from './compare/compare.component';
import { Checkout_pageComponent } from './checkout/component/checkout_page/checkout_page.component';
import { ShoppingcartComponent } from './cart/component/shoppingcart/shoppingcart.component';
import { CartComponent } from './cart/cart.component';
import { ShipAddressComponent } from './checkout/component/ship-address/ship-address.component';
import { YourOrderComponent } from './checkout/component/your-order/your-order.component';
import { YourPaymentComponent } from './checkout/component/your-payment/your-payment.component';
import { ShopPageComponent } from './shop-page/component/shop-page/shop-page.component';
import { MapComponent } from './contact/component/map/map.component';
import { SmartSizeComponent } from './coman/smart-size/smart-size.component';
import { QuestionComponent } from './coman/question/question.component';
import { AnswerComponent } from './coman/answer/answer.component';
import { SmartTailorComponent } from './coman/smart-tailor/smart-tailor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginatorComponent } from './paginator/paginator.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultInterceptor } from 'src/core/interceptors/default.interceptor';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// import { SmartTailorComponent } from './coman/smart-tailor/smart-tailor.component';
import { Question1Component } from './coman/question1/question1.component';
import { Answer1Component } from './coman/answer1/answer1.component';
import { SmartComponent } from './coman/smart/smart.component';
import { RegistrtionPageComponent } from './registrtion-page/registrtion-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaFormsModule } from 'ng-recaptcha';
import { Module as StripeModule } from 'stripe-angular';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CheckoutSuccessComponent } from './checkoutSuccess/checkout-success/checkout-success.component';
import { ModalModule} from 'ngx-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { OrderComponent } from './myAccount/order/order.component';
import { MyAccountComponent } from './myAccount/my-account/my-account.component';
import { ProfileComponent } from './myAccount/profile/profile.component';
import { ProfileAddressComponent } from './myAccount/profile-address/profile-address.component';
import { AddAddressComponent } from './myAccount/profile-address/add-address/add-address.component';
import { FabricColorComponent } from './product/component/fabric-color/fabric-color.component';
import { PagesComponent } from './pages/pages.component';
import { CategoryGridFullwidthComponent } from './shop-page/component/shop-page/category-grid-fullwidth/category-grid-fullwidth.component';
import { ProductRatingComponent } from './myAccount/order/product-rating/product-rating.component';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import {RatingModule} from 'ngx-rating';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageComponent } from './image-cropper copy/image.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from 'src/core/shared/cache-route-reuse.strategy';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AuthenticationComponent,
    BlogGridComponent,
    BlogListComponent,
    BlogMasonryComponent,
    BlogSingleComponent,
    CheckoutComponent,
    CompareComponent,
    ContactComponent,
    QuickViewComponent,
    ShoppingcartComponent,
    WishlistComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    IndexComponent,
    LayoutComponent,
    BannerComponent,
    ProductListComponent,
    HomeComponent,
    PromotionsComponent,
    ProductDailyDealComponent,
    ServicesComponent,
    SpecialLookComponent,
    SectionBlogComponent,
    BlockSocialComponent,
    ShopComponent,

    ProductComponent,
    ProductFullwidthComponent,
    ShopFullwidthComponent,
    HomeComponent,
    ContactDetailComponent,
    SellproductComponent,
    ImagebarComponent,
    ArechtechFigerComponent,
    ProductDescriptionComponent,
    ProductProductTagComponent,
    CustomerReviewComponent,
    DescriptionComponent,
    Checkout_pageComponent,
    CartComponent,
    ShipAddressComponent,
    YourOrderComponent,
    YourPaymentComponent,
    ShopPageComponent,
    MapComponent,
    SmartSizeComponent,
    QuestionComponent,
    AnswerComponent,
    SmartTailorComponent,
    PaginatorComponent,
    SmartTailorComponent,
    Question1Component,
    Answer1Component,
    SmartComponent,
    RegistrtionPageComponent,
    LoginPageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CheckoutSuccessComponent,
    ProfileComponent,
    MyAccountComponent,
    OrderComponent,
    ProfileAddressComponent,
    AddAddressComponent,
    FabricColorComponent,
    PagesComponent,
    CategoryGridFullwidthComponent,
    ProductRatingComponent,
    ImageComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AutocompleteLibModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule ,
    LoadingBarHttpClientModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    StripeModule.forRoot(),
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
     // ToastrModule added

    ModalModule.forRoot(),
   // RatingModule,
    NgxPaginationModule,
RatingModule ,
ImageCropperModule
  ],
  exports: [HomeComponent],
  entryComponents: [
    AddAddressComponent, ProductRatingComponent, ImageComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '<6Lf6s9YUAAAAAKE5CP-wCib9LiDReyI9mitLxlHH>'}
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
