import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Answer1Component } from '../answer1/answer1.component'
import { environment } from 'src/environments/environment';
import { RegisterService } from 'src/core/service/register.service';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { smartTailor } from 'src/core/shared/model/smartTailor';


@Component({
  selector: 'app-question1',
  templateUrl: './question1.component.html',
  styleUrls: ['./question1.component.css']
})
export class Question1Component implements OnInit {
  @ViewChild('file', { static: false }) file: any;
  @Input() question:any;
  @Output() onSkipdata: EventEmitter<any> = new EventEmitter<any>();
  isImage:any;
imgpath = environment.hostname;
previewUrl:any;
imageData: any=[];
imageStatus:boolean;
BASE64_MARKER = ';base64,';
  categoryUploadErrorMessage: string;
  files: Array<any> = new Array<any>();
imageName:any;
smartTailormodel =new smartTailor();
editurl: any;
Url:any;
filenativeElement: any;
questionAnswerArray = [];
questioncurrent:any;
user:any;
  constructor(public service:RegisterService) { 
  }

  ngOnInit() {
    const k=this.question;
    this.user=JSON.parse(localStorage.getItem('currentUser'));
  }
  addQuesAns()
  {
   
    
    this.questionAnswerArray= JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));

    if (this.questionAnswerArray && this.questionAnswerArray.length > 0) {
      const isquestionAnswer = this.questionAnswerArray.find(questionAnswer => questionAnswer.questionId === this.smartTailormodel.questionId);
      if (isquestionAnswer) {
        const objIndex = this.questionAnswerArray.findIndex(questionAnswer => questionAnswer.questionId== isquestionAnswer.questionId);
        this.questionAnswerArray.splice(objIndex, 1);
        const k=isquestionAnswer;
        this.questionAnswerArray.push(this.smartTailormodel);
      }
      else{
        this.questionAnswerArray.push(this.smartTailormodel);
      }
    } else {
      this.questionAnswerArray=[];
      this.questionAnswerArray.push(this.smartTailormodel);
    }
    localStorage.setItem(LocalStorage.QUESTIONANSWER, JSON.stringify(this.questionAnswerArray));
 
  }
  removeImage(index){
    
    this.previewUrl='';
   // this.previewUrl.splice(index, 1);
    this.imageData.splice(index, 1);
}
async handleFileInput(event,question) {
  
  this.questioncurrent=question;
  this.imageStatus=false;
  this.editurl = '';
  this.categoryUploadErrorMessage = '';
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    this.files.push(event.target.files[0]);
    reader.onload = event => {

     this.Url = reader.result;
     this.previewUrl=this.Url;
   //  this.previewUrl.push(this.Url);
    //  var file = new File([this.convertDataURIToBinary(reader.result)],'test.jpg');
    //  this.imageData.push(file);
    };
  }
  const nativeElement: HTMLInputElement = this.file.nativeElement;
  this.filenativeElement = nativeElement;

  this.imageData.push(event.target.files[0]);
  
  this.file.nativeElement.value = null;
  for (let i = 0; i < this.imageData.length; i++) {
    const imgobj = this.imageData[i];
    const f = await this.service.addProductImage(imgobj).toPromise();
    this.imageName=f.data;
  }
  
  this.smartTailormodel.questionId=question.questionId;
  this.smartTailormodel.userId=this.user.userId;
  this.smartTailormodel.isImage=true;
  this.smartTailormodel.imagePath=this.imageName;
  this.addQuesAns();
}
  public skip(data: any):void {
  this.onSkipdata.emit();
}
}