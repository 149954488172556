import { Component, ViewChild, OnInit } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from './image-cropper/interfaces/index';
import {base64ToFile} from './image-cropper/utils/blob.utils';
import {BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from 'src/core/service/shared.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    imgPath: any;
    data: any;
    modelContent: any;
    mgdata: any;
   width: any;
   height: any;
   coordinates = {x: 0}; // object that saves the crop coordinates
ratio: any;
status: any;
public onClose;
constructor(public bsModalRef: BsModalRef, public sharedService: SharedService
  ) {
  }
    ngOnInit() {

        this.modelContent = this.data;
        this.imgPath = this.modelContent;
        this.ratio = 3 / 4;
        this.height = 200;
        this.width = 200;
    }
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    crop() {
        this.bsModalRef.hide();
    // this.cropImage.emit(this.croppedImage);
    }

    close() {
        this.status = 'close';
        this.bsModalRef.hide();
    // this.cropImage.emit(this.croppedImage);
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(event, base64ToFile(event.base64));
    }

    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    cropperReady() {
        //console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }
}
