import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { RegisterService } from 'src/core/service/register.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { AddAddressComponent } from './add-address/add-address.component';
import { SmartTailorComponent } from 'src/app/coman/smart-tailor/smart-tailor.component';
import { SharedService } from 'src/core/service/shared.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-profile-address',
  templateUrl: './profile-address.component.html',
  styleUrls: ['./profile-address.component.css']
})
export class ProfileAddressComponent implements OnInit {
  addressData=[];
  add:string;
  public modalRef: BsModalRef;
  constructor(public service:RegisterService,private modalService: BsModalService,public toastr:ToastrService
    ,public updateService:SharedService) { }
  ngOnInit() {
    this.updateService.addressSelectedData.subscribe(data => {
      this.getAddress();
    });
this.getAddress();

  }
   getAddress() {
    this.service.getAddressdata().subscribe(res => {
      this.addressData = res.data;
   });
}

addAddress()
{
  this.modalRef=this.modalService.show(AddAddressComponent, {class: 'modal-lg'});
}
editAddress(data)
{
  
  //localStorage.setItem('addressId',data.addressId);
this.add=data.addressId;
const initialState=
{
  data:data.addressId
}
this.modalRef=this.modalService.show(AddAddressComponent, {class: 'modal-lg',initialState});
this.modalRef.content.myContent=this.add;
}
deleteAddress(data)
{
  
if (confirm('Are you sure you want to delete?')) {
  this.service.deleteAddress(data.addressId).subscribe((data: any) => {
    if (data.statusCode !== 0) {

      this.toastr.success('Success ', 'Data Deleted');
      this.getAddress();
    } else {
      this.toastr.error('Invalid', 'Opps somthing went wrong');
    }
  });
}
}
}
