import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { smartTailor } from 'src/core/shared/model/smartTailor';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { SmartTailorService } from 'src/core/service/smart-tailor.service';
import { async } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { RegisterService } from 'src/core/service/register.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ImageComponent } from 'src/app/image-cropper copy/image.component';
import { SharedService } from 'src/core/service/shared.service';

@Component({
  selector: 'app-answer1',
  templateUrl: './answer1.component.html',
  styleUrls: ['./answer1.component.css']
})
export class Answer1Component implements OnInit {
  @ViewChild('file', { static: false }) file: any;
  Ans=[]
  @Input() ansArray:any;
  @Output() onSkip: EventEmitter<any> = new EventEmitter<any>();
  i: any;
  grid = true;
 countofans;
 answerForm:FormGroup;
 arrayofans=[];
 k=[];
 islblclick =false;
 skip:boolean;
 anyExtraOption:boolean;
smartTailormodel =new smartTailor();
ExtraAnswer:any;
questionAnswerArray = [];
user:any;
smartTailorData=[];
selected:boolean=false;
isImage:any;
imgpath = environment.hostname;
previewUrl:any;
imageData: any=[];
imageStatus:boolean;
BASE64_MARKER = ';base64,';
  categoryUploadErrorMessage: string;
  files: Array<any> = new Array<any>();
imageName:any;
editurl: any;
Url:any;
questioncurrent:any;
filenativeElement: any;
image:any;
imageArray:any=[];
status:any;
imageUpload:boolean=true;
public modalRef: BsModalRef;
  constructor(private fb: FormBuilder,public smartTailorService:SmartTailorService,public service:RegisterService,
    private modalService: BsModalService,
    public sharedService:SharedService) {
   }
  ngOnInit() {
    
    
    this.user=JSON.parse(localStorage.getItem('currentUser'));
    this.arrayofans=[];
    this.arrayofans=this.ansArray.answers;
    this.isImage=this.ansArray.isImage;
    this.skip=this.ansArray.skip;
    this.anyExtraOption=this.ansArray.anyExtraOption;
    this.countofans=this.ansArray.columns;

    

   setTimeout(()=>{    //<<<---    using ()=> syntax
         this.questionAnswerArray= JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));

        if(this.questionAnswerArray)
        {
        const isquestionAnswer = this.questionAnswerArray.find(questionAnswer => questionAnswer.questionId === this.ansArray.questionId);
          if (isquestionAnswer) {
            
            if(isquestionAnswer.answerID=='ExtraAnswer')
            {
            this.ExtraAnswer=isquestionAnswer.answers;
            }
          }
          // this.image=
          // {
          //   image:isquestionAnswer.imagePath,
          //   questionId:isquestionAnswer.questionId
          // }
          
          this.editurl=isquestionAnswer.imagePath;
          if(this.editurl==null||this.editurl=='')
          {
          this.imageUpload=true;
          }
          else
          {
            this.imageUpload=false
          }
         // this.imageArray.push(this.image);
      }
 }, 1000);
 
  }
  
  removeImage(){
    
    
    this.previewUrl='';
   this.imageName='';
   this.editurl='';
  // this.image.image='';
   this.imageUpload=true;
   this.smartTailormodel.questionId=this.ansArray.questionId;
  this.smartTailormodel.userId=this.user.userId;
  this.smartTailormodel.isImage=true;
  this.smartTailormodel.answers=this.imageName;
  this.smartTailormodel.imagePath=this.imageName;
  this.addQuesAns();
 
}
async handleFileInput(event) {

  
  this.arrayofans.forEach(d=>d.isSelected=false);
  
 // this.questioncurrent=question;
  this.imageStatus=false;
  //this.editurl = '';
  this.categoryUploadErrorMessage = '';
  this.imageUpload=false;
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);
    this.files.push(event.target.files[0]);
    reader.onload = event => {

     this.Url = reader.result;
     
     const initialState=
     {
       data:this.Url 
     }
     this.modalRef=this.modalService.show(ImageComponent, {class: 'modal-lg',initialState});
     
     
     this.modalService.onHide.subscribe(async data=>{
       
       var c= data;
       this.status=this.modalRef.content.status;
       if(this.status!='close')
       {
         
        this.previewUrl=this.modalRef.content.croppedImage;
        // this.image={
        //   image:this.previewUrl
        // }
       var file = new File([this.convertDataURIToBinary(this.previewUrl)],'test.jpg');
       this.imageData.push(file);
     //  this.file.nativeElement.value = null;
       for (let i = 0; i < this.imageData.length; i++) {
         const imgobj = this.imageData[i];
         const f = await this.service.addProductImage(imgobj).toPromise();
         this.imageName=f.data;
       }
       
       this.smartTailormodel.questionId=this.ansArray.questionId;
       this.smartTailormodel.userId=this.user.userId;
       this.smartTailormodel.isImage=true;
       this.smartTailormodel.answers=this.imageName;
       this.smartTailormodel.imagePath=this.imageName;
       this.addQuesAns();
      }
   // this.previewUrl.push(this.Url);
     
     })
    // this.previewUrl=this.Url;
   //  this.previewUrl.push(this.Url);
    //  var file = new File([this.convertDataURIToBinary(reader.result)],'test.jpg');
    //  this.imageData.push(file);
    };
  }
  
  const k=this.previewUrl;
  const nativeElement: HTMLInputElement = this.file.nativeElement;
  this.filenativeElement = nativeElement;

//  this.imageData.push(event.target.files[0]);

 
}

convertDataURIToBinary(dataURI) {
  
  var base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for( let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
  answerInput(j)
  {
    
    var curItem=this.arrayofans[j];
    if(this.selected==false)
    if(curItem.isSelected){
       curItem.isSelected=false;
     }
     else{
      this.arrayofans.forEach(d=>d.isSelected=false);
    curItem.isSelected=true;
    this.selected=true;
    }
    this.smartTailormodel.questionId=this.ansArray.questionId;
    this.smartTailormodel.userId=this.user.userId;
    this.smartTailormodel.answers=this.ExtraAnswer;
    this.smartTailormodel.answerID=this.ansArray.answers[j].answerID;
    this.addQuesAns();
  }
  lblclick(j){
    
    this.ExtraAnswer='';
    this.selected=false;
     this.smartTailormodel.questionId = this.ansArray.questionId;
    this.smartTailormodel.userId=this.user.userId;
    var curItem=this.arrayofans[j];
    if(curItem.isSelected){
      curItem.isSelected=false;
      this.questionAnswerArray= JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));
         if (this.questionAnswerArray && this.questionAnswerArray.length > 0) {
      const isquestionAnswer = this.questionAnswerArray.find(questionAnswer => questionAnswer.questionId === this.smartTailormodel.questionId);
      if (isquestionAnswer) {
        const objIndex = this.questionAnswerArray.findIndex(questionAnswer => questionAnswer.questionId== isquestionAnswer.questionId);
        this.questionAnswerArray.splice(objIndex, 1);
          localStorage.setItem(LocalStorage.QUESTIONANSWER, JSON.stringify(this.questionAnswerArray));
  
      }
    } 
    }
    else{
      this.arrayofans.forEach(d=>d.isSelected=false);
      curItem.isSelected=true;
        this.smartTailormodel.answers=curItem.answer;
  this.smartTailormodel.answerID=this.ansArray.answers[j].answerID;
    this.addQuesAns();
    }
   
  
  }
  addQuesAns()
  {
    
    this.questionAnswerArray= JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));

    if (this.questionAnswerArray && this.questionAnswerArray.length > 0) {
      const isquestionAnswer = this.questionAnswerArray.find(questionAnswer => questionAnswer.questionId === this.smartTailormodel.questionId);
      if (isquestionAnswer) {
        const objIndex = this.questionAnswerArray.findIndex(questionAnswer => questionAnswer.questionId== isquestionAnswer.questionId);
        this.questionAnswerArray.splice(objIndex, 1);
        const k=isquestionAnswer;
        this.questionAnswerArray.push(this.smartTailormodel);
      }
      else{
        this.questionAnswerArray.push(this.smartTailormodel);
      }
    } else {
      this.questionAnswerArray=[];
      this.questionAnswerArray.push(this.smartTailormodel);
    }
    localStorage.setItem(LocalStorage.QUESTIONANSWER, JSON.stringify(this.questionAnswerArray));
  if(this.smartTailormodel.answerID=="skip")
  {
    this.onSkip.emit();
  }
  }
}
