import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-smart-size',
  templateUrl: './smart-size.component.html',
  styleUrls: ['./smart-size.component.css'],
  
})
export class SmartSizeComponent implements OnInit {
 

  i=0;
  count=0;
  questionArrayLength:number;
  mainAns:any;
  Main: { "question": any; "ans": any; }[];

  questionno15: { question: string; answers: { count: number; data: string[]; }; }[];
  questionno14: { question: string; answers: { count: number; data: string[]; }; }[];
  questionno12: { question: string; answers: { count: number; data: string[]; }; }[];
  questionno11: { question: string; answers: { count: number; data: string[]; }; }[];
  questionno10: { question: string; answers: { count: number; data: string[]; }; }[];
  questionno9:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno8:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno7:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno6:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno5:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno4:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno3:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno2:  { question: string; answers: { count: number; data: string[]; }; }[];
  questionno1:  { question: string; answers: { count: number; data: string[]; }; }[];
  question: any;
  value=0;
  progressbarValue = 0;
  progressbarstring:any;

  



  constructor() {
  
    
  

this.question=[
  
    {
      questionNo:1,
      question:"what is your size ?",
      answers:{count:6,
                data:['Extra Small','Small','Large','medium']}
    },

    {
       questionNo:2,
      question:" What size t-shirts do you typically wear?",
      answers:{count:4,
                data:['Extra Small','Small','Large']}
    },
    
    {
      questionNo:4,
      question:"add to work?",
      answers:{count:4,
                data:['Extra Small','Small','Large']}
    },
    {
      questionNo:4,
      question:"what is your question?",
      answers:{count:4,
                data:['XXL','12XL','7XL']}
    },
  {
    questionNo:5,
      question:"What is Today Question?",
      answers:{count:4,
                data:['medidum','Small','Large']}
    },
    {
      questionNo:6,
      question:"what is your size",
      answers:{count:4,
                data:['4XL','4XL','5XL']}
    },
    {
      questionNo:7,
      question:"what is your size?",
      answers:{count:4,
                data:['Small','meduium','Large']}
    },
    {
      questionNo:8,
      question:"what is your size",
      answers:{count:4,
                data:['Extra Large',' Extra Small','XL']}
    },  
    {
      questionNo:9,
      question:"what is your size",
      answers:{count:12,
                data:['XXXL','Small','Large']}
    },

    {
      questionNo:10,
      question:"what is your size",
      answers:{count:4,
                data:[' XXL','Small','Large']}
    },
  {
    questionNo:11,
      question:"what is your size",
      answers:{count:4,
                data:[' 12XL','Small','Large']}
    },
    {
      questionNo:12,
      question:"what is your size",
      answers:{count:4,
                data:['6XL','7XL','8XL']}
    },
    {
      questionNo:14,
      question:"what is your size",
      answers:{count:4,
                data:['9XL','10XL','11XL']}
      },
    {
      questionNo:14,
      question:"what is your size",
      answers:{count:4,
                data:['15XL','18XL','14XL']}
    },
    {
      questionNo:15,
      question:"Size?",
      answers:{count:4,
                data:['15XL','18XL','14XL']}
    },
  ]
} 
  
  ngOnInit() {
    
   
    this.questionArrayLength=this.question.length;
  }
  
 
next()
{
  this.i=this.i+1;
this.progressbarValue=100/this.questionArrayLength;
this.value =this.value + this.progressbarValue;
}
previous(){
  this.i=this.i- 1;   
  this.progressbarValue=100/this.questionArrayLength;
  this.value =this.value - this.progressbarValue;
}

}








