import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RegisterService {
  constructor(private http: HttpClient) {}
  registration(data): Observable<any> {
    return this.http.post<any>(environment.endpoints.registration, data);
  }

  addUpdateCart(Data) {
    return this.http.post<any>(environment.endpoints.addUpdateUserCart, Data);
  }
  getUserCart() {
    return this.http.get<any>(environment.endpoints.getUserCart);
  }
  async addCart(data) {
    return await this.addUpdateCart(data).toPromise();
  }
  async getCart() {
    return await this.getUserCart().toPromise();
  }
  getAddressdata() {
    return this.http.get<any>(environment.endpoints.getAddress);
  }
  getTax(){
    return this.http.get<any>(environment.endpoints.getTax);
  }
  payment(x){
    return this.http.post<any>(environment.endpoints.payment,x);
  }
  addAddress(data){
    return this.http.post<any>(environment.endpoints.addAddress,data);
  }
  orderAdd(data){
    return this.http.post<any>(environment.endpoints.orderAdd,data);
  }

AddSmartTailorMasters(Data) {
  
  return this.http.post<any>(environment.endpoints.AddSmartTailorMasters, Data);
}

getOrderListByUserId(skipvalue,takevalue) {
  const Data = {
    skip: skipvalue,
    take: takevalue,
  isDescending: 'false'
  }
  return this.http.post<any>(environment.endpoints.GetOrderListByUserId,Data);
}

Contact(Data) {
  
  return this.http.post<any>(environment.endpoints.Contact, Data);
}
UpdateProfile(Data) {
  
  return this.http.post<any>(environment.endpoints.UpdateProfile, Data);
}
getProfile(id) {
  return this.http.get<any>(
    environment.endpoints.getProfile + '?id=' + id
  );
}

deleteAddress(AddressId) {
  return this.http.get<any>(
    environment.endpoints.deleteAddress + '?AddressId=' + AddressId
  );
}


getpagesDataByUrlSlug(UrlSlug){
  return this.http.get<any>(environment.endpoints.getpagesDataByUrlSlug  + '?UrlSlug=' + UrlSlug);
}

getAddressByAddressId(AddressId){
  return this.http.get<any>(environment.endpoints.getAddressByAddressId  + '?AddressId=' + AddressId);
}

updateAddress(data){
  return this.http.post<any>(environment.endpoints.updateAddress,data);
}

getCategoryList(){
  
  return this.http.post<any>(environment.endpoints.getCategoryList,'');
}

getProductListByUrlslug(UrlSlug,skipvalue,takevalue){
  const Data = {
       skip: skipvalue,
       take: takevalue,
     isDescending: 'false',
     UrlSlug:UrlSlug
     }
   return this.http.post<any>(environment.endpoints.getProductListByUrlslug,Data);
 }

addProductImage(userModel: any): Observable<any> {
  let input = new FormData();
  input.append('file', userModel);

  return this.http.post<any>(environment.endpoints.addProductImage, input);
}

addReview(Data) {
  return this.http.post<any>(environment.endpoints.addReview, Data);
}

getProductReview(id)
{
  
  return this.http.get<any>(environment.endpoints.getProductReview  + '?id=' + id);
}
IsUseCouponCode(data) {
  return this.http.get<any>(
    environment.endpoints.isCouponCode + '?Couponcode=' + data);
}
}
