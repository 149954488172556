export class smartTailor{
    smartTailorId:string;
    questionId:string;
    answers:string;
    answerID:string;
    userId:string;
    isImage:boolean;
    imagePath:string;
    isDelete: Boolean;
    createdBy: string;
    createdDate: string;
    modifiedDate:string;
    modifiedBy: string;
}