import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from 'src/core/service/register.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit {
contactForm:FormGroup;
submitted:boolean=false;
response:any;
  constructor(private fb:FormBuilder,public service:RegisterService,public toastr:ToastrService,private router:Router) {
    this.contactForm=this.fb.group(
      {
        name:['',[Validators.required]],
        email:['',[Validators.required,Validators.email,Validators.pattern("[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}")]],
        subject:[''],
        message:['',[Validators.required]]
      }
    );
   }

  ngOnInit() {
  }

  sendMessage()
  {
    this.submitted=true;
    if(this.contactForm.valid)
    {
      
      this.service.Contact(this.contactForm.value)
      .subscribe(async (data: any) => {
        if (data.statusCode !== 0) {
          this.response = data.data;
        //  this.router.navigate(['/home']);
          this.toastr.success('successfully', 'your message is send to Admin');
         this.contactForm.reset();
         this.submitted=false;
         window.scrollTo(0, 0);
        } 
      });
    }
    else
    {
      
    }
  }
}
