import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ZipCode } from 'src/app/shared/model/zip-code';
import { RegisterService } from 'src/core/service/register.service';
import { ZipService } from 'src/core/service/zip.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { BsModalRef } from 'ngx-bootstrap';
import { SharedService } from 'src/core/service/shared.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent implements OnInit {
  Address: FormGroup;
  a: FormArray;
  pincode:any;
  addressVisible:boolean;
  addressData:any;
  userData:any;
  filterZip: ZipCode[] = [];
  data:any;
  submitted=false;
  user:any;
  modelContent:any;
  addressId:any;
  lblString="save";
  AddressSelected=false;
  constructor(private fb: FormBuilder,public service:RegisterService,private zipCodeService: ZipService,
    public toastr:ToastrService,  public bsModalRef: BsModalRef,public updateService:SharedService) { 
    this.Address=this.fb.group({
      addressId: (''),
      userId:(''),
    addressName: ['',[Validators.required]],
    addressLine1: ['',[Validators.required]],
    addressLine2: (''),
    city: (''),
    state: (''),
    country: (''),
    phoneNumber: (''),
    pincode: ['',[Validators.required]],
    addressType:(''),
  });
   }

  ngOnInit() {
    this.user=JSON.parse(localStorage.getItem(LocalStorage.CURRENT_USER));
    
    this.modelContent=this.data;
    this.Address.controls['userId'].setValue(this.user.userId);
    this.Address.controls['addressType'].setValue(1);
    
    if(this.modelContent)
    {
      this.getAddressByAddressId();
    }
    // this.modelContent=this.bsModalRef[0];
    // if(this.modelContent!=null||this.modelContent!=undefined)
    // {
    //  
    //  const k=this.modelContent;
    // }
  }
  getAddressByAddressId() {
    this.service.getAddressByAddressId(this.modelContent).subscribe(res => {
      
      this.addressData = res.data;
      this.Address.controls['addressId'].setValue(this.addressData.addressId);
      this.Address.controls['addressName'].setValue(this.addressData.addressName);
      this.Address.controls['addressLine1'].setValue(this.addressData.addressLine1);
      this.Address.controls['addressLine2'].setValue(this.addressData.addressLine2);
      this.Address.controls['city'].setValue(this.addressData.city);
      this.Address.controls['state'].setValue(this.addressData.state);
      this.Address.controls['country'].setValue(this.addressData.country);
      this.Address.controls['phoneNumber'].setValue(this.addressData.phoneNumber);
      this.Address.controls['pincode'].setValue(this.addressData.pincode);
      this.Address.controls['addressType'].setValue(this.addressData.addressType);
      this.Address.controls['userId'].setValue(this.addressData.userId);
      this.lblString='Update';
   });
}
  addUpdateAddress()
  {
    
    // this.modelContent=this.bsModalRef;
    // if(this.modelContent!=null||this.modelContent!=undefined)
    // {
    //  
    //  const k=this.modelContent;
    // }
    if(this.Address.invalid)
    {
      this.submitted = true;
    }
    else{
    if(this.lblString=='save')
{
  this.addAddress();
}  
else
{
this.updateAddress();
}
  }
    //this.addressVisible=false;
  }

  addAddress()
  {
    const k= this.Address.get('pincode').value;
    this.Address.controls['pincode'].setValue(k.Zipcode);
     this.service.addAddress(this.Address.value)
     .subscribe(async (data: any) => {
       if (data.statusCode !== 0) {
         //this.response = data.data;
         this.toastr.success('Success ', 'Added Address');
         this.updateService.addresData('update');
         this.bsModalRef.hide();
         //this.addressVisible=false;
         //this.router.navigate(['/login_page']);
       } else {
         this.toastr.error('Invalid', 'Opps somthing went wrong');
       }
     });
  }
  updateAddress()
  {
    if(this.AddressSelected==true)
    {
    const k= this.Address.get('pincode').value;
    this.Address.controls['pincode'].setValue(k.Zipcode);
    }
     this.service.updateAddress(this.Address.value)
     .subscribe(async (data: any) => {
       if (data.statusCode !== 0) {
         //this.response = data.data;
         this.toastr.success('Success ', 'Update Address');
         this.updateService.addresData('update');
         this.bsModalRef.hide();
         //this.addressVisible=false;
         //this.router.navigate(['/login_page']);
       } else {
         this.toastr.error('Invalid', 'Opps somthing went wrong');
       }
     });
  }
  onChangeSearch(event) {
    
    if (event.length > 3) {
      const a = this.zipCodeService.search(event).subscribe(zipList => {
        
        this.filterZip = zipList;
        this.data = zipList;
      });
    }
    }
  selectEvent(event)
{
  
  this.AddressSelected=true;
  this.Address.get('city').setValue(event.City);
  this.Address.get('state').setValue(event.StareName);
}

}
