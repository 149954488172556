import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AboutComponent } from './about/about.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { BlogGridComponent } from './blog-grid/blog-grid.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogMasonryComponent } from './blog-masonry/blog-masonry.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';

import { CheckoutComponent } from './checkout/checkout.component';
import { CompareComponent } from './compare/compare.component';
import { ContactComponent } from './contact/contact.component';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { HeaderComponent } from './Layout/header/header.component';
import { MenuComponent } from './Layout/menu/menu.component';
import { FooterComponent } from './Layout/footer/footer.component';
import { IndexComponent } from './index/index.component';
import { LayoutComponent } from './Layout/layout/layout.component';
import { BannerComponent } from './home/component/banner/banner.component';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop..component';
import { ProductComponent } from './product/product.component';
import { CartComponent} from './cart/cart.component';
import { ShopPageComponent} from './shop-page/component/shop-page/shop-page.component';
import { SmartSizeComponent} from './coman/smart-size/smart-size.component';
import { QuestionComponent } from './coman/question/question.component';
import { SmartTailorComponent } from './coman/smart-tailor/smart-tailor.component';
import { Question1Component } from './coman/question1/question1.component';
import { Answer } from './shared/model/answer';
import { Answer1Component } from './coman/answer1/answer1.component';
import { SmartComponent } from './coman/smart/smart.component';
import { RegistrtionPageComponent } from './registrtion-page/registrtion-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CheckoutSuccessComponent } from './checkoutSuccess/checkout-success/checkout-success.component';
import { AuthGuardService } from 'src/core/service/auth-guard.service';
import { ProfileComponent } from './myAccount/profile/profile.component';
import { MyAccountComponent } from './myAccount/my-account/my-account.component';
import { OrderComponent } from './myAccount/order/order.component';
import { ProfileAddressComponent } from './myAccount/profile-address/profile-address.component';
import { PagesComponent } from './pages/pages.component';
import { CategoryGridFullwidthComponent } from './shop-page/component/shop-page/category-grid-fullwidth/category-grid-fullwidth.component';
const routes: Routes = [
   {
     path: '',
     component: LayoutComponent,
     children: [
    {path: '', redirectTo: 'home',  pathMatch: 'full'},
     {path: 'home', component: ShopComponent},
     {path: 'home/:urlSlug', component: ShopComponent},

     {path: 'product/:urlSlug', component: ProductComponent},
     {path: 'checkout', component: CheckoutComponent,  canActivate: [AuthGuardService]},
     {path: 'shop-page', component: ShopPageComponent,
     children: [
      {path: 'category', component: CategoryGridFullwidthComponent},
      {path: 'category/:urlSlug', component: CategoryGridFullwidthComponent},
     ]},
     {path: 'cart', component: CartComponent},
     {path: 'contact', component: ContactComponent},
     {path: 'question', component: QuestionComponent},
     {path: 'registration_page', component: RegistrtionPageComponent},
     {path: 'login_page', component: LoginPageComponent},
     {path: 'forgot-password', component: ForgotPasswordComponent},
     {path: 'reset-password', component: ResetPasswordComponent},
     {path: 'checkout-success', component: CheckoutSuccessComponent},
     {path: 'pages/:urlSlug', component: PagesComponent},
     {path: 'myaccount', component: MyAccountComponent,
     children: [
      {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
      {path: 'order', component: OrderComponent, canActivate: [AuthGuardService]},
      {path: 'profileAddress', component: ProfileAddressComponent, canActivate: [AuthGuardService]}
     ]
    }

     ]
   },

   {path: 'smart-tailor', component: SmartTailorComponent},


];

@NgModule({
  imports:  [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

