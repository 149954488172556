import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderUpdateService {
  cartItems=[];
  constructor() { }

//   cartItem(): Observable<any>{
//     this.cartItems=JSON.parse(localStorage.getItem('productCart'));
//   //  return this.cartItems;

// }
}
