import { ProductGetService } from "src/core/service/product-get.service";
import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { HeaderUpdateService } from "../../../core/service/header-update.service";
import { CartAddService } from "src/core/service/cart-add.service";
import { ToastrService } from "ngx-toastr";
import { SmartTailorService } from "src/core/service/smart-tailor.service";
import { LocalStorage } from "src/core/shared/constants/localStorage";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SmartTailorComponent } from "src/app/coman/smart-tailor/smart-tailor.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public btnLabale: string;
  token: any;
  cureentuser: any;
  userdata;
  ipAddress: any;
  user: any = [];
  cartItems: any = [];
  quantity = 0;
  qty = [];
  imgpath = environment.hostname;
  total = 0;
  itemtotal: number;
  quantityStatus = [];
  totalItems: number;
  smartTailorData;
  users: any;
  currentUser: any;
  productCartItem = [];
  @Input() userLoginData;
  public modalRef: BsModalRef;
  constructor(
    private router: Router,
    private cartAddService: CartAddService,
    public HeaderUpdateService: HeaderUpdateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private productGetService: ProductGetService,
    public smartTailorService: SmartTailorService,
    private modalService: BsModalService,
    private cdf: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.token = localStorage.getItem("token");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.token != null || this.token != "") {
      this.cartAddService.changeData(this.currentUser);
    }
    this.cartAddService.serviceData.subscribe((data) => {
      this.cdf.detectChanges();
      if (data == "logout") {
        this.userLoginData = "";
        this.btnLabale = "Login";
        this.quantity = 0;
        this.total = 0;
        this.cartItems = [];
      } else if (data == "checkout") {
        this.quantity = 0;
        this.total = 0;
        this.cartItems = [];
      } else {
        this.cartItems = JSON.parse(localStorage.getItem("productCart"));
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this.user = this.userLoginData;
        this.userLoginData = this.currentUser;
        this.btnLabale = "Logout";
        this.totalcal();
      }
    });
  }
  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("productCart");
    localStorage.removeItem(LocalStorage.CART);
    localStorage.removeItem(LocalStorage.QUESTIONANSWER);
    this.btnLabale = "Login";
    this.cartAddService.changeData("logout");
    this.toastr.success("Logout Successfuly");
    this.router.navigate(["/login_page"]);
  }
  login() {
    this.router.navigate(["/login_page"]);
  }
  totalcal() {
    if (this.cartItems == null) {
      this.total = 0;
      this.quantity = 0;
    } else {
      this.quantity = 0;
      this.total = 0;
      for (let i = 0; i < this.cartItems.length; i++) {
        this.itemtotal = this.cartItems[i].Qty * this.cartItems[i].price;
        this.total = this.total + this.itemtotal;
        this.quantity = this.quantity + this.cartItems[i].Qty;
      }
    }
  }
  async removeItem(index) {
    this.cartItems.splice(index, 1);
    if (this.currentUser != null) {
      const cartValue = {
        cartData: JSON.stringify(this.cartItems),
      };
      this.productGetService.addUpdateCart(cartValue).subscribe(async (res) => {
        const a = res;
        await this.totalcal();
      });
    } else {
      const res = await this.productGetService.getIPAddress().toPromise();
      this.ipAddress = res;
      const cartValue = {
        IpAddress: this.ipAddress.ip,
        cartData: JSON.stringify(this.cartItems),
      };
      this.productGetService
        .addUpdateCartVisitor(cartValue)
        .subscribe((res) => {
          const a = res;
          this.totalcal();
        });
    }

    localStorage.setItem("productCart", JSON.stringify(this.cartItems));
    const ab = JSON.parse(localStorage.getItem("productCart"));
    this.cartAddService.changeData(ab);
    this.total = 0;
  }
  getsmartTailorData() {
    this.smartTailorService.getsmartTailorData().subscribe((res) => {
      this.smartTailorData = res.data;
    });
  }
  checkOut() {
    this.token = localStorage.getItem(LocalStorage.TOKEN);
    if (this.token == "" || this.token == null) {
      this.toastr.error("Login to Get Measured");
      this.router.navigate(["/login_page"]);
    } else {
      this.modalRef = this.modalService.show(SmartTailorComponent, {
        class: "modal-lg-increase",
        initialState: "y",
      });
    }
  }

  checkoutmenu() {
    //     this.token = localStorage.getItem(LocalStorage.TOKEN);
    //     if (this.token == '' || this.token == null) {
    //   this.toastr.error('Must need to login befor checkout');
    //   this.router.navigate(['/login_page']);
    // } else {
    this.router.navigate(["/checkout"]);
  }
  //}
  Cart() {
    this.productCartItem = JSON.parse(localStorage.getItem("productCart"));
    if (this.productCartItem.length > 0) {
      this.router.navigate(["/cart"]);
    } else {
      this.toastr.error("Please select Item.");
    }
  }
}
