import { Injectable } from '@angular/core';
import { LocalStorage } from '../shared/constants/localStorage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor() { }

isLogin(){
  return localStorage.getItem(LocalStorage.TOKEN)? true: false;
}
getCurrentUser(){
  return localStorage.getItem(LocalStorage.CURRENT_USER);
}

getCartProduct()
{
  return localStorage.getItem(LocalStorage.CART)
}
}
