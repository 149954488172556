import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
declare function imageZoom(): any; // just change here from arun answer.
@Component({
  selector: 'app-imagebar',
  templateUrl: './imagebar.component.html',
  styleUrls: ['./imagebar.component.css']
})
export class ImagebarComponent implements OnInit {
  @Input() productFeatureImage: any;
  @Input() productMasterImage: any;
  @Input() productThumbail: any;
  imgpath = environment.hostname;
  constructor() {
   }
   ngOnInit() {
    setTimeout(function() {
      imageZoom();
   }, 10);
   }
   zoomImage(index) {
     
     return this.imgpath + 'images/' + this.productMasterImage[index];
   }
   zoomfeatureImage() {
    
    return this.imgpath + 'images/' + this.productMasterImage[0];
   }
}
