import { Component, OnInit } from '@angular/core';
import { RegisterService } from 'src/core/service/register.service';
import { environment } from 'src/environments/environment';
import { ProductRatingComponent } from './product-rating/product-rating.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  orderlist=[];
  imgpath = environment.hostname;
  totalRecords:number;
  skip:number=0;
  result:number=8;
  public modalRef: BsModalRef;
  constructor(public serivce:RegisterService,private modalService: BsModalService) { }

  ngOnInit() {
    this.getOrderListByUserId();
  }


  getOrderListByUserId()
  {
    
    this.serivce.getOrderListByUserId(this.skip,this.result).subscribe(res => {
      this.orderlist = res.data.order;
      
      this.totalRecords=res.data.totalResults;
    });
  }
  activePage:number = 0;  
  displayActivePage(activePageNumber:number){  
    
    this.activePage = activePageNumber  
    if(this.activePage==1)
    {
      this.skip=0;
     this.getOrderListByUserId();
    }
    else
    {
      this.skip=(this.activePage-1)*this.result;
      this.getOrderListByUserId();
    }
  }  
  displayActivePageForFeature(activePageNumber:number){  
    
    this.activePage = activePageNumber  
    if(this.activePage==1)
    {
      this.skip=0;
     this.getOrderListByUserId();
    }
    else
    {
      this.skip=(this.activePage-1)*this.result;
      this.getOrderListByUserId();
    }
  } 

  productRating(product,orders)
  {
    
    const initialState=
{
  data:product,
  datas:orders
}
this.modalRef=this.modalService.show(ProductRatingComponent, {class: 'modal-lg',initialState});
  }
}
