import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductGetService } from 'src/core/service/product-get.service';
import { ToastrService } from 'ngx-toastr';
import { wishList } from 'src/core/shared/model/wishList';
import { WishListService } from 'src/core/service/wish-list.service';
import { async } from '@angular/core/testing';
import { RegisterService } from 'src/core/service/register.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-product-fullwidth',
  templateUrl: './product-fullwidth.component.html',
  styleUrls: ['./product-fullwidth.component.css']
})
export class ProductFullwidthComponent implements OnInit {
  urlSlug: string;
  productData: any;
  productFeatureImage: string;
  productThumbnail: any = [];
  productMasterImage = [];
  token:any;
  response:any;
  currentUser:any;
  productReview=[];
  wishListobj=new wishList;
  showDetails=true;
  constructor(private router: Router,
              private route: ActivatedRoute,
              public productGetService: ProductGetService,
              private toastr: ToastrService,
              public wishListService:WishListService,
              public service:RegisterService,
              public location : Location) {
    }

  async ngOnInit() {
    this.token = localStorage.getItem('token');
    this.currentUser = localStorage.getItem('currentUser');
    
    window.scrollTo(0, 0);
    this.urlSlug = this.route.snapshot.paramMap.get('urlSlug');
    if (this.urlSlug) {
       await this.getEditData();
       // this.btnLabel = 'Update';

      }
      
  }
   async getEditData() {
     
    var res= await this.productGetService.getProductDatabyId(this.urlSlug).toPromise();
    this.productData = res.data;
      this.productFeatureImage = this.productData.productFeatureImage;
      this.productMasterImage = this.productData.productMasterImage;
      this.productThumbnail =  this.productData.productThumbnail;
    this.showDetails=true;
  }
  backClicked() {
    this.location.back();
  }
  AddtoWishlist()
  {
    if(this.token=='')
    {
      this.toastr.success('Must need to login to add item to wishlist');
    }
    else{
      this.wishListobj.productId=this.productData.productId;
      this.wishListobj.userId=this.currentUser.userId;
      this.wishListService
      .AddtoWishlist(this.wishListobj)
      .subscribe(async (data: any) => {
        if (data.statusCode !== 0) {
          this.response = data.data;
          this.toastr.success('Success ', 'Data Inserted');
         // this.router.navigate(['/category/category-list']);
        } else {
          this.toastr.error('Invalid', 'Opps somthing went wrong');
        }
      });
     // this.toastr.success('add wishlist');
    }
  }

  getProductReview() {
    
     this.service.getProductReview(this.productData.productId).subscribe(res => {
    const k=res;
    this.productReview=res;
   });
  }
}
