import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-product-tag',
  templateUrl: './product-product-tag.component.html',
  styleUrls: ['./product-product-tag.component.css']
})
export class ProductProductTagComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
