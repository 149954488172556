import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ZipService } from 'src/core/service/zip.service';
import { ZipCode } from 'src/app/shared/model/zip-code';
@Component({
  selector: 'app-ship-address',
  templateUrl: './ship-address.component.html',
  styleUrls: ['./ship-address.component.css']
})
export class ShipAddressComponent implements OnInit {
  newAddress = false;
  dropDownShow: boolean;
  @Input() addressList: any;
  @Input() sameAsBillingAddress: any;
  @Input() isSubmit;
  filterZip: ZipCode[] = [];
    data: any;
    zipcode: any;
  constructor(private formBuilder: FormBuilder, private zipCodeService: ZipService) {}

  ngOnInit() {
    this.newAddress = false;

  }
  ngAfterViewInit(): void {
    this.DefualtAddress();
  }
  DefualtAddress() {
      
      if (this.sameAsBillingAddress && this.sameAsBillingAddress.length > 0) {
        
        this.newAddress = true;
        const item = this.sameAsBillingAddress[0];
        this.sameAsBillingAddress.control.addressType1.setValue('newAddress');
        this.sameAsBillingAddress.controls.addressId.setValue(item.addressId);
        this.sameAsBillingAddress.controls.addressName.setValue(item.addressName);
        this.sameAsBillingAddress.controls.addressLine1.setValue(item.addressLine1);
        this.sameAsBillingAddress.controls.addressLine2.setValue(item.addressLine2);
        this.sameAsBillingAddress.controls.city.setValue(item.city);
        this.sameAsBillingAddress.controls.state.setValue(item.state);
        this.sameAsBillingAddress.controls.country.setValue(item.country);
        this.sameAsBillingAddress.controls.phoneNumber.setValue(item.phoneNumber);
        this.sameAsBillingAddress.controls.pincode.setValue(item.pincode);
        this.zipcode = item.pincode;
       }
  }
  handleChange(event) {
    
    const checkValue = event.target.value;
    if (checkValue !== 'oldAddress') {
      this.newAddress = true;
      this.dropDownShow = false;
      this.sameAsBillingAddress.reset();
      // this.sameAsBillingAddress.controls.pincode.setValue('');
      this.sameAsBillingAddress.get('addressType1').setValue('newAddress');
    } else {
      this.newAddress = false;
      this.sameAsBillingAddress.reset();
      this.sameAsBillingAddress.get('addressType1').setValue('oldAddress');
      this.dropDownShow = true;
    }
  }
  expression(item) {
    
    const a = item;
    this.newAddress = true;
    this.sameAsBillingAddress.controls.addressType1.setValue('oldAddress');
    this.sameAsBillingAddress.controls.addressId.setValue(item.addressId);
    this.sameAsBillingAddress.controls.addressName.setValue(item.addressName);
    this.sameAsBillingAddress.controls.addressLine1.setValue(item.addressLine1);
    this.sameAsBillingAddress.controls.addressLine2.setValue(item.addressLine2);
    this.sameAsBillingAddress.controls.city.setValue(item.city);
    this.sameAsBillingAddress.controls.state.setValue(item.state);
    this.sameAsBillingAddress.controls.country.setValue(item.country);
    this.sameAsBillingAddress.controls.phoneNumber.setValue(item.phoneNumber);
    this.sameAsBillingAddress.controls.pincode.setValue(item.pincode);
    this.zipcode=item.pincode;
  }
  onChangeSearch(event) {

if (event.length > 3) {
  const a = this.zipCodeService.search(event).subscribe(zipList => {
    
    this.filterZip = zipList;
    this.data = zipList;
  });
}
}
  selectEvent(event) {
  
  this.sameAsBillingAddress.get('city').setValue(event.City);
  this.sameAsBillingAddress.get('state').setValue(event.StareName);
}


}
