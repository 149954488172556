import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(private http: HttpClient) { }

  getAllquestion(skipvalue,takevalue) {
    
    const Data = {
      skip: 0,
      take: 1000,
    isDescending: 'false'
    }
    return this.http.post<any>(environment.endpoints.getAllquestion,Data);
  
  }
  getProductDatabyId(id){
    return this.http.get<any>(environment.endpoints. getAllquestion+ '?id=' + id);
  }

}
