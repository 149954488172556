import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { wishList } from '../shared/model/wishList';
import { HttpClient } from '@angular/common/http';
import { ResponeArray } from 'src/app/shared/model/ResponeArray';
import { User } from 'src/app/shared/model/user';
import { environment } from 'src/environments/environment';
import { ResponeObj } from 'src/app/shared/model/response-obj';
import { PagedItemSet } from 'src/app/shared/model/paged-item-set';

@Injectable({
  providedIn: 'root'
})
export class WishListService {
  constructor(private http: HttpClient) {}
  AddtoWishlist(wishListModel: wishList): Observable<ResponeArray<User>> {
    return this.http.post<ResponeArray<User>>(environment.endpoints.AddtoWishlist, wishListModel);
  }

  getProductWishlistById(ProductId){
    
    return this.http.get<any>(environment.endpoints.getProductWishlistById  + '?ProductId=' + ProductId);
  }
  deleteProductWishlist(ProductId)
  {
    return this.http.get<ResponeObj<PagedItemSet<any>>>(environment.endpoints.deleteProductWishlist +"?ProductId="+ProductId);
  }
}
