// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { SmartTailorComponent } from 'src/app/coman/smart-tailor/smart-tailor.component';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @ViewChild('stickyMenu',{static: false}) menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;
  productCartItem=[];
  isVisible: boolean;
  public modalRef: BsModalRef;

  constructor( private modalService: BsModalService,public router: Router,private toastr: ToastrService) { }

  ngOnInit() {
    if(localStorage.getItem('productCart')) {
      this.productCartItem = JSON.parse(localStorage.getItem('productCart'));
      if(this.productCartItem){
        if (this.productCartItem.length > 0) {
          this.isVisible = true;
        } else {
      //  this.toastr.error('Please select Item.');
        }
      }
     
    }

  }


  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
  }
  smartTailor() {

    if (localStorage.getItem(LocalStorage.TOKEN)) {
    this.modalRef = this.modalService.show(SmartTailorComponent, {class: 'modal-lg-increase'});
    } else {
this.toastr.error('Login for your Custom Measurement');
this.router.navigate(['./login_page']);
    }
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(event) {
      const windowScroll = window.pageYOffset;
      if (windowScroll >= this.elementPosition) {
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    }
}
