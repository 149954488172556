import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router,private toastr: ToastrService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    if (localStorage.getItem('currentUser')) {
        return true;
    }

    // navigate to login page
    this.toastr.error('Must need to login');
    this.router.navigate(['/login_page']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
