import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sellproduct',
  templateUrl: './sellproduct.component.html',
  styleUrls: ['./sellproduct.component.css']
})
export class SellproductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
