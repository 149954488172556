import { Component, OnInit, Input } from "@angular/core";
import { StripeScriptTag } from "stripe-angular";
import { RegisterService } from "src/core/service/register.service";
import { ToastrService } from "ngx-toastr";

declare var StripeCheckout: any;
@Component({
  selector: "app-your-payment",
  templateUrl: "./your-payment.component.html",
  styleUrls: ["./your-payment.component.css"]
})
export class YourPaymentComponent implements OnInit {
  private publishableKey: string = "pk_test_tg9iCEzM8kaMu99iFqBfcUdq00nI1taip7";

  @Input() totalPrice: any;
  @Input() OrderId:any;
  Data:any;
  handler: any;
  constructor(
    public StripeScriptTag: StripeScriptTag,
    private userService: RegisterService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
   
   
  }

  stripPayment(event) {
    
   
  }
}
