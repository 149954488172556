import { environment } from 'src/environments/environment';
import { Product } from 'src/app/shared/model/product';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ProductGetService {

  constructor(private http: HttpClient) { }

  getAllProduct(skipvalue,takevalue){
    const Data = {
      skip: skipvalue,
      take: takevalue,
    isDescending: 'false'
    }
    return this.http.post<any>(environment.endpoints.getAllProduct,Data);
  }

  getProductDatabyId(UrlSlug){
    return this.http.get<any>(environment.endpoints.getProductDatabyId  + '?UrlSlug=' + UrlSlug);
  }

  GetAllFeatureProduct(){
    return this.http.get<any>(environment.endpoints.GetAllFeatureProduct);
  }
  addUpdateCart(Data) {
    
    return this.http.post<any>(environment.endpoints.addUpdateUserCart, Data);
  } 
  addUpdateCartVisitor(Data){
    return this.http.post<any>(environment.endpoints.addUpdateCartVisitor, Data);

  }
  getUserCart() {
    
    return this.http.get<any>(environment.endpoints.getUserCart);

  }
  getIPAddress()
  {
    return this.http.get('http://api.ipify.org/?format=json');
  }   
  GetProductItem(IpAddress){
    return this.http.get<any>(environment.endpoints.getVisitorCart+ '?IpAddress='+ IpAddress);
  }
}
