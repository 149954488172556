import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, filter, tap, switchMap, finalize } from 'rxjs/operators';
import { ZipCode } from 'src/app/shared/model/zip-code';
import { ZipService } from 'src/core/service/zip.service';

@Component({
  selector: 'app-checkout_page',
  templateUrl: './checkout_page.component.html',
  styleUrls: ['./checkout_page.component.css']
})
export class Checkout_pageComponent implements OnInit {
  newAddress: boolean;
  dropDownShow: boolean;
  isLoading = false;
  @Input() submitt;
  filterZip: ZipCode[] = [];
  itemAddress: [];
  checkValue: any;
  @Input() addressArray: any;
  @Input() bilingAddressArray: any;
  @Input() shipingAddressArray: any;
  isSubmitted = false;

  // keyword = 'name';
  data: any;
  zipcode: any;
  constructor(private formBuilder: FormBuilder, private zipCodeService: ZipService) {}

  ngOnInit() {
    
    const a = this.addressArray;
    this.newAddress = false;

  }
  handleChange(event) {
    
    this.checkValue = event.target.value;
    if (this.checkValue !== 'oldAddress') {
      this.newAddress = true;
      this.bilingAddressArray.reset();
      this.dropDownShow = false;
      this.shipingAddressArray.get('pincode').setValue('');
      this.bilingAddressArray.get('addressType').setValue('newAddress');
    } else {
      this.newAddress = false;
      this.dropDownShow = true;
    }

  }
  expression(item) {
    
    const a = item;
    this.newAddress = true;
    this.bilingAddressArray.get('addressId').setValue(item.addressId);
    this.bilingAddressArray.get('addressName').setValue(item.addressName);
    this.bilingAddressArray.get('addressLine1').setValue(item.addressLine1);
    this.bilingAddressArray.get('addressLine2').setValue(item.addressLine2);
    this.bilingAddressArray.get('city').setValue(item.city);
    this.bilingAddressArray.get('state').setValue(item.state);
    this.bilingAddressArray.get('country').setValue(item.country);
    this.bilingAddressArray.get('phoneNumber').setValue(item.phoneNumber);
    this.bilingAddressArray.get('pincode').setValue(item.pincode);
    this.zipcode = item.pincode;
}
 async Checkbox(event) {
  
  // this.itemAddress = await this.addressForm.value;
  const item = this.bilingAddressArray.value;
  this.shipingAddressArray.get('addressId').setValue(item.addressId);
  this.shipingAddressArray.get('addressType1').setValue('newAddress');
  this.shipingAddressArray.get('addressName').setValue(item.addressName);
  this.shipingAddressArray.get('addressLine1').setValue(item.addressLine1);
  this.shipingAddressArray.get('addressLine2').setValue(item.addressLine2);
  this.shipingAddressArray.get('city').setValue(item.city);
  this.shipingAddressArray.get('state').setValue(item.state);
  this.shipingAddressArray.get('country').setValue(item.country);
  this.shipingAddressArray.get('phoneNumber').setValue(item.phoneNumber);
  this.shipingAddressArray.get('pincode').setValue(item.pincode.Zipcode);
  // this.zipcode=item.pincode.Zipcode;
}
onChangeSearch(event) {

if (event.length > 3) {
  const a = this.zipCodeService.search(event).subscribe(zipList => {
    
    this.filterZip = zipList;
    this.data = zipList;
  });
}
}
selectEvent(event) {
  
  this.bilingAddressArray.get('city').setValue(event.City);
  this.bilingAddressArray.get('state').setValue(event.StareName);
  this.bilingAddressArray.get('pincode').setValue(event.Zipcode);
}
isSubmitEmitMethod($event) {
  
  this.isSubmitted = $event;
  // if(this.bilingAddressArray.get('phoneNumber').value.length<10)
}
}
