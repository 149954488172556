import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductGetService } from 'src/core/service/product-get.service';
import { ToastrService } from 'ngx-toastr';
import { WishListService } from 'src/core/service/wish-list.service';
import { AuthService } from 'src/core/service/auth.service';
import { CartAddService } from 'src/core/service/cart-add.service';
import { RegisterService } from 'src/core/service/register.service';
import { Product } from 'src/app/shared/model/product';
import { environment } from 'src/environments/environment';
import { wishList } from 'src/core/shared/model/wishList';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
declare function slideshow(): any; // just change here from arun answer.

@Component({
  selector: 'app-category-grid-fullwidth',
  templateUrl: './category-grid-fullwidth.component.html',
  styleUrls: ['./category-grid-fullwidth.component.css']
})
export class CategoryGridFullwidthComponent implements OnInit {
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(
    private router: Router,
    public productGetService: ProductGetService,
    private toastr: ToastrService,
    public wishListService: WishListService,
    private authService: AuthService,
    private userService: RegisterService,
    private cartAddService: CartAddService,
    public route: ActivatedRoute
  ) {
    route.params.subscribe(val => {
      // put the code from `ngOnInit` here
      this.urlSlug = this.route.snapshot.paramMap.get('urlSlug');
      this.GetAllProductByCategory();
    });
  }
  urlSlug: any;
  datalength: any;
  productList: Product[];
  featureProductList: Product[];
  resultsLength = 0;
  skip = 0;
  result = 8;
  totalRecords: number;
  imgpath = environment.hostname;
  editurl: any;
  SalePrice: number;
  Price: number;
  token: any;
  wistlistitem = true;
  wishListobj = new wishList;
  currentUser: any;
  response: any;
  ProdData: any;
  productArrayObject: any;
  productArray = [];
  cartPrice: number;
  activePage = 0;
  productsortArray = [];
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = localStorage.getItem('token');
    setTimeout(function () {
      slideshow();
    }, 2000);

  }
  sort(event: any) {

    this.productList.forEach(element => {
      if (element.productSalePrice == null) {
        element.price = element.productPrice;
      } else {
        element.price = element.productSalePrice;
      }
    });
    switch (event.target.value) {
      case 'Low': {
        this.productList = this.productList.sort((low, high) => low.price - high.price);
        break;
      }

      case 'High': {
        this.productList = this.productList.sort((low, high) => high.price - low.price);
        break;
      }
      case 'menu_order': {
        this.productList = this.productList;
        break;
      }
    }

    return this.productList;
  }

  GetAllProductByCategory() {
    this.userService
      .getProductListByUrlslug(this.urlSlug, this.skip, this.result)
      .subscribe((data) => {
        if (data.data) {
          if (this.activePage <= 1) {
            this.productList = data.data.productListViewModels;
            this.activePage = 1;
          } else {
            if (data.data.productListViewModels.length === 0) {
              this.toastr.error('No more product to load', 'Product');
            } else {
              this.productList = this.productList.concat(
                data.data.productListViewModels
              );
            }
          }
          //  this.productList = data.data.productListViewModels;
          this.totalRecords = data.data.totalCount;
          this.SalePrice = data.data.productListViewModels.prod;
          this.editurl = data.data.productListViewModels.productFeatureImage;
          //  alert(this.totalRecords);

          // this.dataSource.data = this.productList;
          // this.setGrid();
        } else {
          // this.dataSource.data = [];
        }
      });
  }


  particularProduct(data) {

    this.router.navigate(['/product', data.urlSlug]);
    localStorage.setItem('productId', data.productId);
  }
  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    if (this.activePage === 1) {
      this.skip = 0;
      this.GetAllProductByCategory();
      this.toastr.error('no more items');
    } else {
      this.skip = (this.activePage - 1) * this.result;
      this.GetAllProductByCategory();
    }
    // window.scrollTo(0, 0);
  }


  addToCart(productDatafig) {
    this.productArray = JSON.parse(localStorage.getItem(LocalStorage.CART));

    const k = productDatafig;


    if (productDatafig.productSalePrice === '' || productDatafig.productSalePrice == null) {
      this.cartPrice = productDatafig.productPrice;
    } else {
      this.cartPrice = productDatafig.productSalePrice;
    }
    this.productArrayObject = {
      productId: productDatafig.productId,
      image: productDatafig.productFeatureImage,
      fabric: productDatafig.fabricCode,
      name: productDatafig.productName,
      price: this.cartPrice,
      quantity: productDatafig.quantity,
      available: productDatafig.quantity,
      Qty: 1,
    };

    if (productDatafig.quantity <= 0 || productDatafig.quantity === '') {
      this.toastr.error('Item is out of stock');
    } else {
      if (this.productArray && this.productArray.length > 0) {
        const isProduct = this.productArray.find(product => product.productId === this.productArrayObject.productId);
        if (isProduct) {
          const objIndex = this.productArray.findIndex(product => product.productId === isProduct.productId);
          isProduct.Qty += this.productArrayObject.Qty;
          if (isProduct.Qty > isProduct.quantity) {
            this.toastr.error('Quantity Is Not Available');
            return;
          } else {
            this.productArray.splice(objIndex, 1);
            this.productArray.push(isProduct);
          }
        } else {
          this.productArray.push(this.productArrayObject);
        }
      } else {
        this.productArray = [];
        this.productArray.push(this.productArrayObject);
      }
      if (this.authService.isLogin()) {
        const cartValue = {
          cartData: JSON.stringify(this.productArray)
        };
        this.userService.addCart(cartValue);
      }
      localStorage.setItem(LocalStorage.CART, JSON.stringify(this.productArray));
      this.cartAddService.changeData('update');
      this.toastr.success('Product added successfully');
      this.router.navigate(['/cart']);


    }
  }
}

