import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartAddService {
 
  constructor() { 
  }
  private dataSource = new BehaviorSubject('');
  serviceData = this.dataSource.asObservable();
    changeData(cartdata: any) {
      this.dataSource.next(cartdata);
   }
   serviceCartData = this.dataSource.asObservable();
   changeCartData(cartdata: any) {
     this.dataSource.next(cartdata);
  }

}
