import { Component, OnInit } from "@angular/core";
import { RegisterService } from "src/core/service/register.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.css"]
})
export class CheckoutComponent implements OnInit {
  addressNameList: any = [];
  addressForm:FormGroup;
  bilingAddressFormGroup:any;
  shipingAddressFormGroup:any;
  constructor(private userService: RegisterService,private formBuilder: FormBuilder) {}

   ngOnInit() {
     this.getAddressData();
     this.addressForm = this.formBuilder.group({
     bilingAddress:this.formBuilder.group({
        addressId: (''),
      addressName: ['',[Validators.required]],
      addressLine1: ['',[Validators.required]],
      addressLine2: (''),
      city: (''),
      state: (''),
      country:['',[Validators.required]],
      phoneNumber:  ['',[Validators.required]],
      pincode: ['',[Validators.required]],
      addressType:(''),
    }),
      shipingAddress:this.formBuilder.group({
        addressId: (''),
      addressName: ['',[Validators.required]],
      addressLine1: ['',[Validators.required]],
      addressLine2: (''),
      city: (''),
      state: (''),
      country: ['',[Validators.required]],
      phoneNumber:  ['',[Validators.required]],
      pincode:['',[Validators.required]],
       addressType1:(''),
      })
    });
    
    this.bilingAddressFormGroup=this.addressForm.controls.bilingAddress;
    this.shipingAddressFormGroup=this.addressForm.controls.shipingAddress;
  }
  async getAddressData() {
    var dataAddress = await this.userService.getAddressdata().toPromise();

    dataAddress = dataAddress.data;
    dataAddress.forEach(element => {
      
      // var addressName = element.addressName;
      this.addressNameList.push(element);
    });
  }
}
