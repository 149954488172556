import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css']
})
export class AnswerComponent implements OnInit {
  Ans=[]
  @Input() ansArray:any;
  
  i: any;
  grid = true;
 countofans;
 arrayofans=[];



  constructor() { }

  ngOnInit() {
    
    this.countofans=this.ansArray.count;
     this.arrayofans=this.ansArray.data;
  }

}
