import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from 'src/core/service/register.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  urlSlug:any;
  pageData:any;
  constructor(public route:ActivatedRoute,public service:RegisterService) {
    route.params.subscribe(val => {
      // put the code from `ngOnInit` here
      this.urlSlug = this.route.snapshot.paramMap.get('urlSlug');
    if (this.urlSlug) {
        this.getpagesData();
      }
    });
   }

  ngOnInit() {
    
    this.urlSlug = this.route.snapshot.paramMap.get('urlSlug');
    if (this.urlSlug) {
        this.getpagesData();
      }
  }

  getpagesData() {
    
     this.service.getpagesDataByUrlSlug(this.urlSlug).subscribe(res => {
       if(res.data)
       {
this.pageData=res.data;
       }
       else
       {
         
       }
    });
 }

}
