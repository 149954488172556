import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { registration } from 'src/core/shared/model/registration';
import { RegisterService } from 'src/core/service/register.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CartAddService } from 'src/core/service/cart-add.service';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
@Component({
  selector: 'app-registrtion-page',
  templateUrl: './registrtion-page.component.html',
  styleUrls: ['./registrtion-page.component.css']
})
export class RegistrtionPageComponent implements OnInit {
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;
  registerForm: FormGroup;
  submitted = false;
  public counter:number=0;
  constructor(private formBuilder: FormBuilder,
     public registrationService:RegisterService,
     private toastr: ToastrService,
     public router:Router,
    public cartAddService:CartAddService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      lastName:['',[Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      captcha:['']  
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
}
// checkPasswords(group: FormGroup) { // here we have the 'passwords' group
//   let pass = group.get('password').value;
//   let confirmPass = group.get('confirmPass').value;
//   return pass === confirmPass ? null : { notSame: true }     
// }
resolved(captchaResponse: string) {
  console.log(`Resolved captcha with response: ${captchaResponse}`);
}
Register() {
      this.registrationService.registration(this.registerForm.value as registration).subscribe(res =>{
      if(res.statusCode==1){
      this.toastr.success('Registration successfull!y');
      localStorage.setItem('token',res.data.token);
      localStorage.setItem('currentUser',JSON.stringify(res.data));
      this.router.navigate(['/home']);
      
      this.cartAddService.changeData(res.data);
    }
    else{
      this.counter++;
      this.toastr.error('User already exist');
    }
      });
}
}
