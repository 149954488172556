import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/core/service/login.service';
import { login } from 'src/core/shared/model/login';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ProductGetService } from 'src/core/service/product-get.service';
import { RegisterService } from 'src/core/service/register.service';
import { CartAddService } from 'src/core/service/cart-add.service';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  @Output() loginChange: EventEmitter<any> = new EventEmitter();
  submitted = false;
  loginForm: FormGroup;
  cartItems: any = [];
  returnUrl:any;
  public counter:number=0;
  previousPage;
  public previousUrl: string = undefined;
  private currentUrl: string = undefined;
  constructor(private formBuilder: FormBuilder,
              private productGetService: ProductGetService,
              public loginservice: LoginService, 
              private toastr: ToastrService,
               public router: Router,
               public userService:RegisterService,
               public cartAddService:CartAddService,
               private route: ActivatedRoute) { }

  ngOnInit() {
    
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
      //userName: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      captcha: ['']
    });
    this.previousUrl=this.route.snapshot.queryParams['redirectTo'];
  //this.previousUrl=  this.route.snapshot.queryParams.get('redirectTo');
  
    // this.currentUrl = this.router.url;
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = event.url;
    //   }
    // });
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
}
login() {
      this.loginservice.login(this.loginForm.value as login).subscribe(res => {
        const k = res;
        

   
        if (res.statusCode == 1) {
          this.toastr.success('Login successfull!!');
          window.scrollTo(0, 0);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.loginChange.emit('login');
          this.getCartItem();
          if(this.previousUrl){
            
            this.router.navigate(['/'+this.previousUrl[0]]);
         //   this.cartAddService.changeCartData(this.cartItems);

          }
          else{
            this.router.navigate(['/home']);

          }
        } else {
          this.counter++;
          this.toastr.error('Invalid Username Or Password');
        }
      });
    }
  getCartItem() {
    
    this.productGetService.getUserCart().subscribe(res => {
      if (res.statusCode === 1) {
        
        const a = JSON.parse(res.data.cartData);
        this.cartItems = res.data.cartData;
        if(a.length >0)
        {
        localStorage.setItem('productCart', this.cartItems);
        }
        this.cartAddService.changeData("ab");
      }
    });
  }
}
