import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductGetService } from 'src/core/service/product-get.service';
import { ToastrService } from 'ngx-toastr';
import { WishListService } from 'src/core/service/wish-list.service';
import { AuthService } from 'src/core/service/auth.service';
import { CartAddService } from 'src/core/service/cart-add.service';
import { RegisterService } from 'src/core/service/register.service';
import { Product } from 'src/app/shared/model/product';
import { environment } from 'src/environments/environment';
import { wishList } from 'src/core/shared/model/wishList';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
declare function slideshow(): any; // just change here from arun answer.
@Component({
  selector: 'app-shop-page',
  templateUrl: './shop-page.component.html',
  styleUrls: ['./shop-page.component.css']
})
export class ShopPageComponent implements OnInit {
  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private Service: RegisterService,
  ) {
    
  }
  categoryList=[];
  url:string;
  ngOnInit() {
    
    this.getCaregoryList();
  }
   
 getCaregoryList() {

    this.Service.getCategoryList().subscribe((data) => {
        if (data.data) {
          
          this.categoryList = data.data.categoryListViewModels;
        }
      });
  }
  redirectToCategory(urlSlug)
  {
    
    this.url='/shop-page/category/'+urlSlug;
    this.router.navigate([this.url])
  }

  
}

