import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/core/service/register.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public btnLabel: string;
  currentUser: any;
  profileForm:FormGroup;
  submitted = false;
  public editMode = false;
  response:any;
  constructor(private fb:FormBuilder,public toastr:ToastrService,public serivce:RegisterService,private router:Router) {
    this.profileForm=this.fb.group({
      userId:['', [Validators.required]],
      firstName:['', [Validators.required]],
      lastName:['', [Validators.required]],
      email:['', [Validators.required]],
      phoneNumber:['', [Validators.required]]
    });
   }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.serivce.getProfile(this.currentUser.userId).subscribe((data) =>
    {
      if(data.data)
      {
        this.currentUser=data.data;
        this.data();
      }

    });
   this.data();
    

  }

  data()
  {
    this.profileForm.controls.userId.setValue(this.currentUser.userId);
    this.profileForm.controls.firstName.setValue(this.currentUser.firstName);
    this.profileForm.controls.lastName.setValue(this.currentUser.lastName);
    this.profileForm.controls.email.setValue(this.currentUser.email);
    this.profileForm.controls.phoneNumber.setValue(this.currentUser.phoneNumber);
    
    this.profileForm.controls.firstName.disable();
    this.profileForm.controls.lastName.disable();
    this.profileForm.controls.email.disable();
    this.profileForm.controls.phoneNumber.disable();
    this.btnLabel = 'Edit profile';
  }
  EditProfile()
  {
    this.submitted = true;
    if (this.editMode === false) {
      this.profileForm.controls.firstName.enable();
      this.profileForm.controls.lastName.enable();
      this.profileForm.controls.email.enable();
      this.profileForm.controls.phoneNumber.enable();
     this.btnLabel = 'Update profile';
     this.editMode = true;
     } else {
       this.serivce.UpdateProfile(this.profileForm.value).subscribe(data => {
        if (data.statusCode !== 0) {
          this.response = data.data;
          this.profileForm.controls.firstName.disable();
          this.profileForm.controls.lastName.disable();
          this.profileForm.controls.email.disable();
          this.profileForm.controls.phoneNumber.disable();
          this.toastr.success('Profile update success');
          this.router.navigate(['/home']);

        } 
      });
     }
 
  }
}
