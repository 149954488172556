import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddSubscriptionServiceService {

  public setMoal = new BehaviorSubject<any[]>([]);

  constructor(
    private http: HttpClient
  ) { }

  addSubscription(emailId):Observable<any>{
    return this.http.post<any>(environment.endpoints.AddSubscription,emailId)
  }

  setValue(value){
    this.setMoal.next(value);
  }

  getValue(){
    return this.setMoal.asObservable();
  }
}
