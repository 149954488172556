import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { QuestionService } from 'src/core/service/question.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { smartTailor } from 'src/core/shared/model/smartTailor';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/core/service/register.service';
import { BsModalService } from 'ngx-bootstrap';
import { SmartTailorService } from 'src/core/service/smart-tailor.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-smart-tailor',
  templateUrl: './smart-tailor.component.html',
  styleUrls: ['./smart-tailor.component.css']
})
export class SmartTailorComponent implements OnInit {
  questionAnsarray: any = [];
  i = 0;
  count = 0;
  questionListArrayLength: number;
  // questionArrayLength:number;
  mainAns: any;
  question: any;
  value = 0;
  progressbarValue = 0;
  progressbarstring: any;
  questionList = [];
  skip: number;
  result: number;
  save_ques_ans: any;
  queAnswerobject = [];
  questionAnswerArray = [];
  smartTailormodel = new smartTailor();
  response: any;
  user: any;
  userId: any;
  Answer: any;
  modelContent: string;
  smartTailorId: any;
  temp = [];
  constructor(private router: Router,
    public questionService: QuestionService,
    private toastr: ToastrService,
    private service: RegisterService,
    private modalService: BsModalService,
    public smartTailorService: SmartTailorService,
    public bsModalRef: BsModalRef

  ) {
  }
  ngOnInit() {
    this.GetAllQuestion();
    //   var c= this.bsModalRef;
  }
  GetAllQuestion() {
    this.questionService.getAllquestion(this.skip, this.result)
      .subscribe((data) => {
        if (data.data) {
          this.questionList = data.data.items;
          this.questionList.forEach(element => {
            element.answers.push({ answerID: "ExtraAnswer", answer: "", type: "userInput" });
            element.answers.push({ answerID: "skip", answer: "I'm not sure,skip this question", type: "skip" });
          });
          if (localStorage.getItem(LocalStorage.TOKEN)) {
            this.getsmartTailorData();
          }
          const objIndex = this.questionList.findIndex(e => e.isImage == true);
          if (objIndex != -1) {
            const len = this.questionList.length;
            this.temp = this.questionList[objIndex];
            this.questionList[objIndex] = this.questionList[len - 1];
            this.questionList[len - 1] = this.temp;
          }
          this.questionListArrayLength = this.questionList.length;
        }
      });
  }
  getsmartTailorData() {

    this.user = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_USER));
    this.smartTailorService.getsmartTailorData().subscribe(res => {

      var smartTailorData = res.data;
      this.questionList.forEach(element => {

        const isquestionAnswer = smartTailorData.find(questionAnswer => questionAnswer.questionId === element.questionId);
        if (isquestionAnswer) {
          var queAnsArray = JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));
          if (queAnsArray != null) {
            this.questionAnswerArray = queAnsArray;
          }
          if (element.anyExtraOption) {
            const isAnswer = element.answers.findIndex(ans => ans.answer == isquestionAnswer.answers);
            if (isAnswer != -1) {
              if (isquestionAnswer.imagePath == '' || isquestionAnswer.imagePath == null) {
                element.answers[isAnswer].isSelected = true;
              }
              this.smartTailormodel.questionId = element.questionId;
              this.smartTailormodel.userId = this.user.userId;
              this.smartTailormodel.answers = element.answers[isAnswer].answer;
              this.smartTailormodel.answerID = element.answers[isAnswer].answerID;
              this.smartTailormodel.isImage = element.isImage;
              this.smartTailormodel.imagePath = isquestionAnswer.imagePath;
              this.addQuesAns();
            }
            else if (isAnswer == -1) {

              var Answer = element.answers.length - 2;
              if (isquestionAnswer.imagePath == '' || isquestionAnswer.imagePath == null) {
                element.answers[isAnswer].isSelected = true;
              }

              this.smartTailormodel.questionId = element.questionId;
              this.smartTailormodel.userId = this.user.userId;
              this.smartTailormodel.answers = isquestionAnswer.answers;
              this.smartTailormodel.answerID = element.answers[Answer].answerID;
              this.smartTailormodel.isImage = element.isImage;
              this.smartTailormodel.imagePath = isquestionAnswer.imagePath;
              this.addQuesAns();
            }
          }
          else {
            const isAnswer = element.answers.findIndex(ans => ans.answer == isquestionAnswer.answers);
            if (isAnswer > -1) {
              if (isquestionAnswer.imagePath == '' || isquestionAnswer.imagePath == null) {
                element.answers[isAnswer].isSelected = true;
              }

              this.smartTailormodel.questionId = element.questionId;
              this.smartTailormodel.userId = this.user.userId;
              this.smartTailormodel.answers = element.answers[isAnswer].answer;
              this.smartTailormodel.answerID = element.answers[isAnswer].answerID;
              this.smartTailormodel.isImage = element.isImage;
              this.smartTailormodel.imagePath = isquestionAnswer.imagePath;
              this.addQuesAns();
            }
            else if (isAnswer == -1) {
              this.Answer = element.answers.length;
              if (isquestionAnswer.imagePath == '' || isquestionAnswer.imagePath == null) {
                element.answers[isAnswer].isSelected = true;
              }

              this.smartTailormodel.questionId = element.questionId;
              this.smartTailormodel.userId = this.user.userId;
              this.smartTailormodel.answers = isquestionAnswer.answers;
              this.smartTailormodel.isImage = element.isImage;
              this.smartTailormodel.imagePath = isquestionAnswer.imagePath;
              this.addQuesAns();
            }
          }
        }
      });
    });
  }
  addQuesAns() {

    this.questionAnswerArray = JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));

    if (this.questionAnswerArray && this.questionAnswerArray.length > 0) {
      const isquestionAnswer = this.questionAnswerArray.find(questionAnswer => questionAnswer.questionId === this.smartTailormodel.questionId);
      if (isquestionAnswer) {
        const objIndex = this.questionAnswerArray.findIndex(questionAnswer => questionAnswer.questionId == isquestionAnswer.questionId);
        this.questionAnswerArray.splice(objIndex, 1);
        const k = isquestionAnswer;
        this.questionAnswerArray.push(this.smartTailormodel);
      }
      else {
        this.questionAnswerArray.push(this.smartTailormodel);
      }
    } else {
      this.questionAnswerArray = [];
      this.questionAnswerArray.push(this.smartTailormodel);
    }
    localStorage.setItem(LocalStorage.QUESTIONANSWER, JSON.stringify(this.questionAnswerArray));
  }
  next() {

    if (localStorage.getItem(LocalStorage.TOKEN)) {
      this.queAnswerobject = JSON.parse(localStorage.getItem(LocalStorage.QUESTIONANSWER));
      const k = this.questionList[this.i];
      if (this.queAnswerobject == null) {
        this.toastr.error('Please select answer');
      }
      else {
        const isquestionAnswer = this.queAnswerobject.find(questionAnswer => questionAnswer.questionId === this.questionList[this.i].questionId);
        if (isquestionAnswer) {

          if (isquestionAnswer.isImage == true) {
            if (isquestionAnswer.imagePath == '' || isquestionAnswer.imagePath == null) {
              this.toastr.error('Please select image');
            }
            else {
              this.i = this.i + 1;
              this.progressbarValue = 100 / this.questionListArrayLength;
              this.value = this.value + this.progressbarValue;
            }
          }
          else {
            if (isquestionAnswer.answers == '') {
              this.toastr.error('Please select answer');
            }
            else {
              this.i = this.i + 1;
              this.progressbarValue = 100 / this.questionListArrayLength;
              this.value = this.value + this.progressbarValue;
            }
          }
        }
        else {
          this.toastr.error('Please select answer');
        }
      }
      if (this.i == this.questionListArrayLength) {
        this.AddSmartTailorMasters(this.queAnswerobject);

      }
    }
    else {
      this.i = this.i + 1;
      if (this.i == this.questionListArrayLength) {
        this.bsModalRef.hide();
      }
    }
  }
  previous() {

    this.i = this.i - 1;
    this.progressbarValue = 100 / this.questionListArrayLength;
    this.value = this.value - this.progressbarValue;
  }
  public skipQue(data: any): void {

    this.next();
  }

  AddSmartTailorMasters(data) {
    this.service
      .AddSmartTailorMasters(data)
      .subscribe(async (data: any) => {
        if (data.statusCode !== 0) {

          this.response = data.data;
          this.smartTailorId = this.response.smartTailorId;
          localStorage.setItem('smartTailorId', this.smartTailorId);
          this.toastr.success('Success ', 'Data Inserted');
          localStorage.removeItem(LocalStorage.QUESTIONANSWER);

          this.modelContent = this.bsModalRef.content[0];
          this.bsModalRef.hide();
          if (this.modelContent != null || this.modelContent != undefined) {

            this.router.navigate(['/checkout']);
          }

        } else {
          this.toastr.error('Invalid', 'Opps somthing went wrong');
        }
      });
  }

}