import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SmartTailorService } from "src/core/service/smart-tailor.service";
import { CartAddService } from "src/core/service/cart-add.service";
import { ProductGetService } from "src/core/service/product-get.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SmartTailorComponent } from "src/app/coman/smart-tailor/smart-tailor.component";
import { LocalStorage } from "src/core/shared/constants/localStorage";
@Component({
  selector: "app-shoppingcart",
  templateUrl: "./shoppingcart.component.html",
  styleUrls: ["./shoppingcart.component.css"],
})
export class ShoppingcartComponent implements OnInit {
  shoppingCartForm: FormGroup;
  cartItems = [];
  smartTailorData;
  qty = [];
  imgpath = environment.hostname;
  total = 0;
  itemtotal: number;
  user: any;
  userId: string;
  token: any;
  ipAddress: any;
  quantityStatus = [];
  cartValue: any = [];
  productCartItem = [];
  public modalRef: BsModalRef;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public smartTailorService: SmartTailorService,
    private productGetService: ProductGetService,
    public cartAddService: CartAddService,
    private modalService: BsModalService
  ) {
    this.shoppingCartForm = this.fb.group({
      quantity: ["", Validators.min(1)],
    });
  }

  ngOnInit() {
    this.productCartItem = JSON.parse(localStorage.getItem("productCart"));
    if (this.productCartItem == null || this.productCartItem.length < 0) {
      this.router.navigate(["./home"]);
      this.toastr.error("Please select Item.");
    } else {
      this.user = JSON.parse(localStorage.getItem("currentUser"));
      this.cartItems = JSON.parse(localStorage.getItem("productCart"));
      // this.cartValue.push(this.cartItems);
      this.qty = this.cartItems;
      this.totalcal();
      if (this.user != null) {
        this.getsmartTailorData();
      }
      // this.cartAddService.serviceCartData.subscribe(data => {
      //
      //     this.cartItems = JSON.parse(data);
      // });
    }
  }
  async qtyChange(data) {
    const k = this.qty[data].Qty;
    const m = this.cartItems[data].quantity;
    if (this.qty[data].Qty < 1) {
      this.quantityStatus[data] = "Product qunatity must be greater than 1";
    } else {
      if (this.qty[data].Qty > this.cartItems[data].quantity) {
        this.quantityStatus[data] =
          "Only " +
          this.cartItems[data].quantity +
          " items are available in stock";
        return;
      } else {
        this.quantityStatus[data] = "";
        this.total = 0;
        this.cartItems[data].Qty = this.qty[data].Qty;

        const cartValue = {
          cartData: JSON.stringify(this.cartItems),
        };
        if (k != null) {
          if (this.user != null) {
            this.productGetService.addUpdateCart(cartValue).subscribe((res) => {
              if (res.statusCode === 1) {
                this.toastr.success(res.data);
                this.productCartItem = JSON.parse(
                  localStorage.getItem("productCart")
                );
                if (
                  this.productCartItem.length == 0 ||
                  this.productCartItem == null
                ) {
                  this.router.navigate(["./home"]);
                  this.toastr.error("Please select Item.");
                }
              }
            });
          } else {
            const res = await this.productGetService.getIPAddress().toPromise();
            this.ipAddress = res;
            const cartValue = {
              IpAddress: this.ipAddress.ip,
              cartData: JSON.stringify(this.cartItems),
            };
            this.productGetService
              .addUpdateCartVisitor(cartValue)
              .subscribe((res) => {
                if (res.statusCode === 1) {
                  this.toastr.success(res.data);
                  this.productCartItem = JSON.parse(
                    localStorage.getItem("productCart")
                  );
                  if (
                    this.productCartItem.length == 0 ||
                    this.productCartItem == null
                  ) {
                    this.router.navigate(["./home"]);
                    this.toastr.error("Please select Item.");
                  }
                }
              });
          }
          localStorage.setItem("productCart", JSON.stringify(this.cartItems));
          this.cartItems = JSON.parse(localStorage.getItem("productCart"));
          const ab = JSON.parse(localStorage.getItem("productCart"));
          this.cartAddService.changeData(ab);
          this.totalcal();
        }
      }
    }
  }
  totalcal() {
    if (this.cartItems == null) {
    } else {
      for (let i = 0; i < this.cartItems.length; i++) {
        this.itemtotal = this.cartItems[i].Qty * this.cartItems[i].price;
        this.total = this.total + this.itemtotal;
      }
    }
  }
  async removeItem(index) {
    this.cartItems.splice(index, 1);
    const cartValue = {
      cartData: JSON.stringify(this.cartItems),
    };
    if (this.user != null) {
      this.productGetService.addUpdateCart(cartValue).subscribe((res) => {
        if (res.statusCode === 1) {
          this.toastr.success(res.data);
        }
      });
    }
    else{
      const res = await this.productGetService.getIPAddress().toPromise();
      this.ipAddress = res;
      const cartValue = {
        IpAddress: this.ipAddress.ip,
        cartData: JSON.stringify(this.cartItems),
      };
      this.productGetService
        .addUpdateCartVisitor(cartValue)
        .subscribe((res) => {
          if (res.statusCode === 1) {
            this.toastr.success(res.data);
          }
        });
    }
    localStorage.setItem("productCart", JSON.stringify(this.cartItems));
    const ab = JSON.parse(localStorage.getItem("productCart"));
    this.cartAddService.changeData(ab);
    this.total = 0;
    this.totalcal();
  }
  getsmartTailorData() {
    this.smartTailorService.getsmartTailorData().subscribe((res) => {
      this.smartTailorData = res.data;
    });
  }
  checkOut() {
    this.token = localStorage.getItem(LocalStorage.TOKEN);
    if (this.token == "" || this.token == null) {
      this.toastr.error("Login for your Custom Measurement");
      this.router.navigate(["/login_page"], {
        queryParams: { redirectTo: this.route.snapshot.url },
      });
    } else {
      this.modalRef = this.modalService.show(SmartTailorComponent, {
        class: "modal-lg-increase",
        initialState: "y",
      });
    }
  }
}
