import { Component, OnInit } from '@angular/core';
import { CartAddService } from 'src/core/service/cart-add.service';

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.css']
})
export class CheckoutSuccessComponent implements OnInit {
  ordernumber:any;
  sub=false;
  constructor(   private cartAddService: CartAddService) { }

  ngOnInit() {
    this.ordernumber=localStorage.getItem('OrderNumber');
    localStorage.removeItem('productCart');
    this.cartAddService.changeData('checkout');
  }

}
