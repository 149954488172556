import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ForgotPasswordService } from 'src/core/service/forgot-password.service';
import { ToastrService } from 'ngx-toastr';


export function MustMatch(controlName: string, matchingControlName: string) {
return (formGroup: FormGroup) => {
  const control = formGroup.controls[controlName];
  const matchingControl = formGroup.controls[matchingControlName];
  if (matchingControl.errors && !matchingControl.errors.mustMatch) {
    // return if another validator has already found an error on the matchingControl
    return;
  }
  // set error on matchingControl if validation fails
  if (control.value !== matchingControl.value) {
    matchingControl.setErrors({ mustMatch: true });
  } else {
    matchingControl.setErrors(null);
  }
};
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  submitted = false;
  resetForm:FormGroup;
  resetPasswordObject:any;
  token:any;
  constructor( public formBuilder:FormBuilder,public route:ActivatedRoute,public service:ForgotPasswordService,
    private toastr: ToastrService,public router:Router) { }
  ngOnInit() {
    this.resetForm=this.formBuilder.group({
      password:  ['',[Validators.required, Validators.minLength(6)]],
      confirmPassword:[''],
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.token =  this.route.snapshot.queryParams['token'];
  }
  resetPassword(){
    
    this.resetPasswordObject={
      "password":this.resetForm.controls['password'].value,
      "token":this.token
    }
    this.submitted = true;
    if(this.resetForm.invalid)
    {
     
    }
    else
    {
 this.service
      .resetPassword(this.resetPasswordObject)
      .subscribe(async (data: any) => {
        if (data.statusCode !== 0) {
          //this.response = data.data;
          this.toastr.success('Success ', 'Password Reset');
          this.router.navigate(['/login_page']);
        } else {
          this.toastr.error('Invalid', 'Opps somthing went wrong');
        }
      });
    }

  }
}