import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ZipCode } from 'src/app/shared/model/zip-code';
import { RegisterService } from 'src/core/service/register.service';
import { ZipService } from 'src/core/service/zip.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorage } from 'src/core/shared/constants/localStorage';
import { BsModalRef } from 'ngx-bootstrap';
import { SharedService } from 'src/core/service/shared.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.css']
})
export class ProductRatingComponent implements OnInit {
  @ViewChild('file', { static: false }) file: any;
  reviews: FormGroup;
  //starsCount:any=2.5;
  modelContent:any;
  val='2.5';
previewUrl:any=[];
data:any;
datas:any;
imageStatus:boolean;
BASE64_MARKER = ';base64,';
  categoryUploadErrorMessage: string;
imageData: any=[];
imageName:any=[];
editurl: any;
Url:any;
filenativeElement: any;
productobj: any;
dataobj: any = [];
oderData:any;
  imgpath = environment.hostname;
  imageUploadVisible:boolean;
ratingErrorStatus:any;
imageErrorStatus:any;
  files: Array<any> = new Array<any>();
  constructor(private fb: FormBuilder,public service:RegisterService,
    public toastr:ToastrService,  public bsModalRef: BsModalRef,public updateService:SharedService) { 
    this.reviews=this.fb.group({
      productReviewId: (''),
      productId:(''),
      fkorderId:(''),
      userId:(''),
      rating:(''),
      comments:(''),
      image:('')
  });
   }
  ngOnInit() {
    
   // this.starsCount=3;
    this.modelContent=this.data;
    this.oderData=this.datas;
   this.reviews.get('productId').setValue(this.modelContent.productId);
   this.reviews.get('userId').setValue(this.oderData[0].userId);
   this.reviews.get('fkorderId').setValue(this.oderData[0].orderId);
  }
  removeImage(index){
    
  //  this.previewUrl='';
    this.previewUrl.splice(index, 1);
    this.imageData.splice(index, 1);
    if(this.imageData.length>=3)
    {
     this.imageUploadVisible=true;
    }
    else{
      this.imageUploadVisible=false;
    }
}

//   }
openFile(){
  //console.log('hell')
  document.querySelector('input').click()
}
convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for( let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}
  async handleFileInput(event) {
    
    this.imageStatus=false;
    this.editurl = '';
    this.categoryUploadErrorMessage = '';
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      this.files.push(event.target.files[0]);
      reader.onload = event => {

       this.Url = reader.result;
       this.previewUrl.push(this.Url);
      //  var file = new File([this.convertDataURIToBinary(reader.result)],'test.jpg');
      //  this.imageData.push(file);
      };
    }
    const nativeElement: HTMLInputElement = this.file.nativeElement;
    this.filenativeElement = nativeElement;

    this.imageData.push(event.target.files[0]);
    
    this.file.nativeElement.value = null;
    this.imageErrorStatus="";
    if(this.imageData.length>=3)
    {
     this.imageUploadVisible=true;
    }
  }
 
  async addReview()
  {
    
//     if(this.imageData.length<1)
//     {
// this.imageErrorStatus="Image needed to be selected";
//     }
    if(this.reviews.get('rating').value<1)
    {
      this.toastr.error(' ', 'Rating is necessary');
    }
    else
    {
      
  if(this.imageData.length>0){
    for (let i = 0; i < this.imageData.length; i++) {
      const imgobj = this.imageData[i];
      const f = await this.service.addProductImage(imgobj).toPromise();
      this.imageName.push(f.data);
     }
    }
    const a = this.reviews.value;
    this.dataobj.push(a);
    this.dataobj.forEach(element => {
      element.image = this.imageName;
      
      this.productobj = element;
    });
    this.service.addReview(this.productobj).subscribe((res =>
      {
        const k=res;
        
        if(res.statusCode==1)
        {
          this.toastr.success('Success ', 'Review Added');
          this.bsModalRef.hide();
        }
      }))
  }
  }
}
