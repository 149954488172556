import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { RegisterService } from "src/core/service/register.service";
import { LocalStorage } from "src/core/shared/constants/localStorage";
import { ɵCssKeyframesDriver } from "@angular/animations/browser";
import { StripeScriptTag } from "stripe-angular";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
declare var StripeCheckout: any;
@Component({
  selector: "app-your-order",
  templateUrl: "./your-order.component.html",
  styleUrls: ["./your-order.component.css"],
})
export class YourOrderComponent implements OnInit {
  private publishableKey = "pk_live_35CojHfDLlXVBEvnYBSTTBek00aIIHacHl";
  total: any;
  itemtotal: any;
  totalPrice: any;
  taxprice: any;
  CouponCodeId: any;
  productCart = [];
  tax: any;
  isDisable:boolean;
  couponcode: any;
  order: any;
  @Input() addressShipingList: any;
  @Input() bilingAddressList: any;
  @Input() isSubmit: boolean;
  @Output() isSubmitEmit = new EventEmitter<boolean>();
  billingAddId: any;
  shippingAmount: any;
  shipingAddId: any;
  orderDetailsObj: any;
  orderDetails: any = [];
  orderId: any;
  handler: any;
  Data: any;
  IsAmmount: any;
  totalAmount: any;
  DiscountValue: any;
  totalCCode: any;
  response: any;
  billingValue: any;
  checkoutButton: boolean;
  isValidBilling: boolean;
  isValidShiping: boolean;
  imgpath = environment.hostname;
  SmartTailorId: any;
  constructor(
    private router: Router,
    private userService: RegisterService,
    public StripeScriptTag: StripeScriptTag,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.checkoutButton = true;
    this.shippingAmount = 0.0;
    this.billingValue = this.bilingAddressList.value.addressType;
    if (this.billingValue !== "") {
      this.checkoutButton = false;
    }
    this.productCart = JSON.parse(localStorage.getItem("productCart"));
    await this.getTax();
    this.StripeScriptTag.setPublishableKey(this.publishableKey);

    this.handler = StripeCheckout.configure({
      key: "pk_live_35CojHfDLlXVBEvnYBSTTBek00aIIHacHl",
      locale: "auto",
      token: (token) => {
        console.log("token" + JSON.stringify(token));
        this.paymentApiCall(token);
      },
    });
    setTimeout(() => {
      this.isSubmit = true;
    }, 5000);
  }
  removeItem() {
    this.isDisable = false;
    this.IsAmmount = 0;
    this.totalCCode = 0;
    this.totalAmount = this.total;
    this.couponcode = "";
    this.DiscountValue = 0;
  }
  async getTax() {
    const a = await this.userService.getTax().toPromise();

    this.tax = a.data[0].taxPercentage;

    this.totalPrice = 0;
    for (let i = 0; i < this.productCart.length; i++) {
      this.itemtotal = this.productCart[i].Qty * this.productCart[i].price;
      this.totalPrice = this.totalPrice + this.itemtotal;
      // this.quantity = this.quantity + this.productCart[i].Qty;
    }
    this.taxprice = (this.totalPrice * this.tax) / 100;

    this.total = this.totalPrice + this.taxprice + this.shippingAmount;
    this.totalAmount=this.total;
  }
  async orderPlace() {
    this.isSubmit = true;
    this.isSubmitEmit.emit(this.isSubmit);

    if (!this.bilingAddressList.valid) {
      window.scroll(50, 0);
      return;
    }
    if (!this.addressShipingList.valid) {
      window.scroll(50, 0);
      return;
    }

    const addressShipingList = this.addressShipingList.value;
    const bilingAddressList = this.bilingAddressList.value;
    if (bilingAddressList.addressType === "newAddress") {
      bilingAddressList.pincode = bilingAddressList.pincode.Zipcode;
    }
    // if(addressShipingList.addressType1 == 'newAddress'){
    //
    //   addressShipingList.pincode=addressShipingList.pincode.Zipcode;
    // }

    // this.isValidBilling =this.bilingAddressList.valid;
    // this.isValidShiping=this.addressShipingList.valid;
    // {
    if (bilingAddressList.addressType !== "newAddress") {
      this.billingAddId = bilingAddressList.addressId;
    }
    if (addressShipingList.addressType1 !== "newAddress") {
      this.shipingAddId = addressShipingList.addressId;
    }
    if (bilingAddressList.addressType === "newAddress") {
      bilingAddressList.addressType = 1;
      bilingAddressList.pincode = this.bilingAddressList.value.pincode;
      const billingAdd = await this.userService
        .addAddress(bilingAddressList)
        .toPromise();

      this.billingAddId = billingAdd.data.addressId;
    }
    if (addressShipingList.addressType1 === "newAddress") {
      addressShipingList.addressType1 = 2;
      addressShipingList.pincode = this.addressShipingList.value.pincode.Zipcode;
      const shipingAdd = await this.userService
        .addAddress(addressShipingList)
        .toPromise();

      this.shipingAddId = shipingAdd.data.addressId;
    }

    this.totalPrice = 0;

    this.orderDetails = [];
    for (let i = 0; i < this.productCart.length; i++) {
      this.orderDetailsObj = {
        ProductId: this.productCart[i].productId,
        quantity: this.productCart[i].Qty,
        price: this.productCart[i].price,
        totalPrice: this.productCart[i].Qty * this.productCart[i].price,
        fabricDetailsId: this.productCart[i].fabric.fabricDetailsId,
      };

      this.orderDetails.push(this.orderDetailsObj);
    }

    this.SmartTailorId = localStorage.getItem("smartTailorId");
    this.order = {
      // TailorMasterId:"",
      SmartTailorId: this.SmartTailorId,
      TotalAmount: this.totalAmount,
      Tax: this.tax,
      Discount: this.DiscountValue,
      CouponCodeId: this.CouponCodeId,
      shippingAmount: this.shippingAmount,
      BillingAddressId: this.billingAddId,
      ShippingAddressId: this.shipingAddId,
      orderDetails: this.orderDetails,
    };

    const order = await this.userService.orderAdd(this.order).toPromise();

    this.orderId = order.data.orderId;

    if (order.statusCode === 1) {
      localStorage.setItem("OrderNumber", order.data.orderNumber);
      this.handler.open({
        name: "FireStarter",
        excerpt: "Deposit Funds to Account",
        amount:  this.totalAmount * 100,
      });
    }
    if (order.statusCode === 0) {
      this.toastr.error("Please Select Valid Quantity.");
    }
    // }
  }
 
  available() {
    this.isDisable = true;
    const data = this.couponcode;
    this.userService.IsUseCouponCode(data).subscribe(async (data: any) => {
      if (data.statusCode !== 0) {
        this.response = data.data;
        this.CouponCodeId = data.data.couponCodeId;
        this.IsAmmount = data.data.codetype;
        this.totalCCode = data.data.codeValue;
        if (this.IsAmmount == 1) {
          // this.total=this.total-this.taxprice;
          this.totalAmount = (this.totalPrice * this.totalCCode) / 100;
          this.DiscountValue = this.totalPrice - this.totalAmount;

          this.totalAmount=this.totalAmount+this.taxprice;
          if (this.DiscountValue > this.total) {
            this.DiscountValue = 0;
            this.totalAmount = this.total;
            this.toastr.error(
              "Error",
              "This CouponCode not applicable to this Item. "
            );
          }
        } else {
          // this.total=this.total-this.taxprice;
          this.totalAmount = this.totalPrice - this.totalCCode;         
          this.taxprice = (this.totalAmount * this.tax) / 100;
          this.totalAmount=this.totalAmount+this.taxprice;
         // this.total = this.totalAmount + this.taxprice + this.shippingAmount;
          //this.totalAmount=this.total;

          if (this.totalAmount > this.total) {
            this.totalAmount = this.total;
            this.toastr.error(
              "Error",
              "This CouponCode not applicable to this Item. "
            );
          }
        }
        if (this.totalPrice < 100) {
          this.DiscountValue = 0;
          this.totalAmount = this.total;
          this.totalCCode = 0;
          this.toastr.error(
            "Error",
            "This Coupon Code not applicable to this Item. "
          );
        }
        else{
          this.toastr.success("Success", "Applied Successfully copon code");
        }
        
      } else {
        this.totalCCode = this.total;
        this.toastr.error("Error", data.message);
      }
    });
  }
  paymentApiCall(token: any) {
    const x = {
      token: token.id,
      orderId: this.orderId,
      amount: this.totalAmount * 100,
    };
    this.userService.payment(x).subscribe((data: any) => {
      if (data.statusCode === 1) {
        this.toastr.success("Payment Success");
        this.router.navigate(["/checkout-success"]);
      } else {
        this.toastr.error("Payment Failure");
      }
    });
  }
}
