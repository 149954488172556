import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http:HttpClient) { }
  forgotPassword(data): Observable<any>{
    return this.http.get<any>(environment.endpoints.forgotpassword + "?email=" + data);

}
resetPassword(data): Observable<any> {
  return this.http.post<any>(environment.endpoints.resetPassword, data);
}

}
