import { Component, OnInit, Output, Input, ElementRef, ViewChild } from '@angular/core';
import { RegisterService } from 'src/core/service/register.service';
import { environment } from 'src/environments/environment';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
declare function imageZooms(): any; // just change here from arun answer.
declare function loadReviewZoom(): any;
declare function scroll(id): any;
@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css']
})
export class ProductDescriptionComponent implements OnInit {

  @Input() productDescription:any;
  @Input() productReview:any;
  imgpath = environment.hostname;
  p=0;
  constructor(public myElement: ElementRef,private scrollToService: ScrollToService) { }

  ngOnInit() {
    setTimeout(function() {
      imageZooms();
      loadReviewZoom();
   }, 10);
  }
  imageZoon()
  {
    imageZooms();
    loadReviewZoom();
  }
  absoluteIndex(indexOnPage: number) {
  }
  nextReviews($event){
    this.p=$event;
    const config: ScrollToConfigOptions = {
      target: 'comments'
    };

    this.scrollToService.scrollTo(config);
  }
 
}
