// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var host = 'https://simplyafricaapi.azurewebsites.net/';
//var host = 'http://Localhost:57678/';
export const environment = {

  production: false,
  hostname:'https://simplyafricaapi.azurewebsites.net/',
  useHash: false,
  hmr: false,
  endpoints: {
    getAllProduct: host + '/Product/GetAllProductFrontUI',
    getProductDatabyId: host + '/Product/GetProductURLByListFrontUI',
    getAllquestion: host + '/Questions/GetAllQuestionFrontUI',
    registration:host +'/Users/RegistrationFrontUI',
    login:host + '/Users/LoginFrontUI',
    forgotpassword :host+'/Users/CheckEmailVerification',
    getsmartTailorData:host+'/SmartTailorMaster/GetTailorMasters',
    AddtoWishlist:host+'/ProductWishlist/AddProductWishlist',
    GetAllFeatureProduct:host+'/Product/GetFeatureProductListFrontUI',
    getProductWishlistById:host+'/ProductWishlist/ProductWishlistById',
    addUpdateUserCart: host + '/Users/addUpdateUserCart',
    addUpdateCartVisitor:host+'/Users/IpAddressCart',
    getVisitorCart:host+'/Users/getIpAddressCart',
    getUserCart: host + '/Users/getUserCart',
    getAddress:host+'Addresss/AddressGetByUserId',
    getTax:host+'/TaxMaster/GetTaxMaster',
    payment:host+'Payment/Payment',
    addAddress:host+'Addresss/AddAddress',
    orderAdd:host+'Order/AddOrders',
    deleteProductWishlist:host +'/ProductWishlist/deleteProductWishlist',
    AddSmartTailorMasters:host +'/SmartTailorMaster/AddSmartTailorMasters',
    resetPassword:host+'/Users/EmailForgetPassword',
    GetOrderListByUserId:host+'/Order/GetOrderListByUserId',
    Contact:host+'/Contact/NewUser',
    UpdateProfile:host+'/Users/updateProfile',
    getProfile:host+'/Users/GetUserIDByList',
    deleteAddress:host+'/Address/DeleteAddress',
    getpagesDataByUrlSlug:host+'/Pages/GetURLSlugByPagesList',
    getAddressByAddressId:host+'/Address/AddressGetByAddressId',
    updateAddress:host+'/Addresss/UpdateAddress',
    getProductListByUrlslug:host+'Category/URLslugByCategoryList',
    getCategoryList:host+'Category/URLslugCategoryList',
    addProductImage: host + 'FileUpload/UploadFile',
    addReview:host + '/Product/ProductRating',
    getProductReview:host +'/Product/GetProductIDByListFrontUI',
    isCouponCode : host+'/CouponCode/checkCouponCodeAvalible',
    AddSubscription : host+'/api/NewsLeatter/AddSubscription',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
