import { Component, OnInit } from '@angular/core';
import { AddSubscriptionServiceService } from 'src/core/service/add-subscription-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private addSubscriptionService: AddSubscriptionServiceService,
  ) { }

  ngOnInit() {
  }

  openSubscribePopUp(){
    this.addSubscriptionService.setValue(true);
  }

}
