import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-masonry',
  templateUrl: './blog-masonry.component.html',
  styleUrls: ['./blog-masonry.component.css']
})
export class BlogMasonryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
