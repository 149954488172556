import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddSubscriptionServiceService } from 'src/core/service/add-subscription-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'project2';

  @ViewChild('subscribeModal', { static: false }) subscribeModal: ModalDirective;

  subscribeForm: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private _addSubscriptionService: AddSubscriptionServiceService,
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url == "/home") {
          this.subscribeModal.show();
        } else {
          this.subscribeModal.hide();
        }
      }
    });

    this.subscribeForm = this.formBuilder.group({
      emailid: ['', [Validators.required, Validators.email, Validators.pattern("[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{3,}")]],
    });

    this._addSubscriptionService.getValue().subscribe(response => {
      if (response) {
        setTimeout(() => {
          this.subscribeModal.show();
        }, 50);
      } else {
        setTimeout(() => {
          this.subscribeModal.hide();
        }, 50);
      }
    });
  }

  ngOnInit() {
  }

  get f() { return this.subscribeForm.controls; }

  ngAfterViewInit() {
    setTimeout(() => {
      this.subscribeModal.show();
    }, 500);
  }

  hideModal() { 
    this.subscribeModal.hide(); 
    this._addSubscriptionService.setValue(false); 
    this.subscribeForm.reset();
    this.submitted = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.subscribeForm.invalid) {
      return;
    } else {
      this._addSubscriptionService.addSubscription(this.subscribeForm.value).subscribe(addSubscriptionResponse => {
        if (addSubscriptionResponse.isValid === true || addSubscriptionResponse.message === "Subscription Added") {
          this.submitted = false;
          this.hideModal();
          this.subscribeForm.reset();
          this.toastr.success(addSubscriptionResponse.message);
        } else {
          this.toastr.success(addSubscriptionResponse.message);
        }
      });
    }
  }
}
