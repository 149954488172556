import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ZipCode } from 'src/app/shared/model/zip-code';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZipService {

  zipCodeData: any[] = [];
  constructor(private http: HttpClient) {

    this.load().subscribe(data => {
      this.zipCodeData = data;
    }) 
  }
  load(): Observable<any> {
    return this.http.get<any>('assets/data/zipCodes.json?_t=' + Date.now());
  }
  search(searchString: any): Observable<ZipCode[]> {
    return this.http.get<any>('assets/data/zipCodes.json?_t=' + Date.now())
      .pipe(
        map((res: ZipCode[]) => res.filter(z => z.Zipcode.includes(searchString)))
      );
  }
}
