import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/core/service/shared.service';

@Component({
  selector: 'app-fabric-color',
  templateUrl: './fabric-color.component.html',
  styleUrls: ['./fabric-color.component.css']
})
export class FabricColorComponent implements OnInit {
fabricArray=[];
fabricObj:any;
imgpath = environment.hostname;
selected:boolean;
fabricData:any;
@Output() selectedFabric = new EventEmitter<string>();
@Input() fabricDetailsId;
@Input() fabricColors;
  constructor(public sharedService:SharedService) { }

  ngOnInit() {
    
    setTimeout(()=>{ 
    this.fabricArray=this.fabricColors.fabric.fabricDetailsViewModels;
  }, 3000);
   
    this.sharedService.fabricSelectedData.subscribe(data => {
      this.fabricData=data;
      });
  }
  fabricSelect(data)
  {
    
this.fabricData=data;
this.selectedFabric.emit(this.fabricData);
  }
}
